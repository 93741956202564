
import { InformationCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import _ from 'lodash';
import { useState } from 'react';
import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ContactDomains, ContactEmails, ContactLastNote, ContactNumbers } from '../../../components/contact.component';
import { Tag, TagsList } from '../../../components/tag.component';
import { API, ContactHelper, TagsHelper } from '../../../utils/api';
import { Shortcut } from '../../../components/shortcut.component';

const MergeAlert = ({ onMergeClick }) => {
    return <div className="rounded-md bg-blue-50 p-4 mb-6">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-blue-700">There are similar contacts available for a merge.</p>
          <p className="mt-3 text-sm md:ml-6 md:mt-0">
            <button onClick={() => onMergeClick()} className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">Review Suggestion<span aria-hidden="true"> &rarr;</span></button>
          </p>
        </div>
      </div>
    </div>
}

const DeletedAlert = ({ onRestoreClick }) => {
    return <div className="rounded-md bg-red-50 p-4 mb-6">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-red-700">Contact is Deleted</p>
          <p className="mt-3 text-sm md:ml-6 md:mt-0">
            <button onClick={() => onRestoreClick()} className="whitespace-nowrap font-medium text-red-700 hover:text-red-600">Restore Contact<span aria-hidden="true"> &rarr;</span></button>
          </p>
        </div>
      </div>
    </div>
}

export const ContactDetails = ({contact, onNewEmailClick, onNewPhoneClick, onMergeClick, onRestoreClick, onCreateNoteClick}) => {

    const { user: { token } } = useSelector(state => state);
    const [showMergeSuggestions, setShowMergeSuggestions] = useState(false);

    useEffect(() => {

        if(_.isEmpty(contact)) return;

        API.searchByName(token, ContactHelper.getPrimaryName(contact)).then(response => {
            setShowMergeSuggestions((!_.isEmpty(_.filter(response, (result => (result.id !== contact.id))))));
        }).catch(error => {

        })
    }, [contact])

    return <Fragment>
        {contact.deleted && <DeletedAlert onRestoreClick={onRestoreClick} />}
        {showMergeSuggestions && <MergeAlert onMergeClick={onMergeClick} />}
        {contact && contact.tags && !_.isEmpty(contact.tags) && <div className="space-y-6 sm:space-y-0 pb-6 ">
            <TagsList>
                {contact.tags.map(tag => <Tag key={tag}>{TagsHelper.getTagAsLabel(tag)}</Tag>)}
            </TagsList>
        </div>}

        <div className="space-y-6 sm:space-y-0 pb-7 mb-4 border-b border-gray-100 group/emails">
            <span className="mb-4 grid grid-cols-2 items-center">
                <div className="min-w-fit">
                    <h5 className="block text-base font-semibold leading-7 text-gray-900 whitespace-nowrap">Email Addresses</h5>
                    <p className="max-w-2xl text-xs text-gray-500 whitespace-nowrap">All available addresses, press ⌘E to add new.</p>  
                </div>
                <div className="text-right">
                    <button onClick={onNewEmailClick} type="button" className="opacity-0 group-hover/emails:opacity-100 transition-opacity delay-300 rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      New Address <Shortcut shortcuts={["⌘", "E"]} className="ml-1" />
                    </button>
                </div>
            </span>
            <ContactEmails contact={contact} />
        </div>

        <div className="space-y-4 sm:space-y-0 pb-7 mb-4 border-b border-gray-100 group/numbers">
            <span className="mb-4 grid grid-cols-2 items-center">
                <div className="min-w-fit">
                    <h5 className="block text-base font-semibold leading-7 text-gray-900">Phone Numbers</h5>
                    <p className="max-w-2xl text-xs text-gray-500">{_.isEmpty(contact.numbers) ? "No available numbers" : "All available numbers"}, press ⌥⌘N to add new.</p>
                </div>
                <div className="text-right">
                    <button onClick={onNewPhoneClick} type="button" className="opacity-0 group-hover/numbers:opacity-100 transition-opacity delay-300 rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      New Number <Shortcut shortcuts={["⌥", "⌘", "N"]} className="ml-1" />
                    </button>
                </div>
            </span>
            <ContactNumbers contact={contact} />
        </div>

        <div className="space-y-4 sm:space-y-0 pb-7 mb-4 border-b border-gray-100">
            <span className="block mb-4 text-indigo-600">
                <h5 className="block text-base font-semibold leading-7 text-gray-900">Websites</h5>
                <p className="max-w-2xl text-xs text-gray-500">Websites are being collected automatically while importing contacts</p>
            </span>
            <ContactDomains contact={contact} />
        </div>
        <div className="space-y-4 sm:space-y-0 pb-8">
            <span className="block mb-4 text-indigo-600">
                <h5 className="block text-base font-semibold leading-7 text-gray-900">Lastest Note</h5>
                <p className="max-w-2xl text-xs text-gray-500">Your last thought about {ContactHelper.getPrimaryName(contact)}, press TAB to see all</p>
            </span>
            <ContactLastNote contact={contact} onCreateNoteClick={onCreateNoteClick} />
        </div>
    </Fragment>}