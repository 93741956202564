import { API, API_URL } from "../utils/api";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classes from '../scss/onboarding.module.scss'
import { useEffect } from "react";
import { useState } from "react";
import { MainButton } from "../components/button.component";
import { disconnect } from "../store/slices/user.slice";
import _ from "lodash";
import { CircularProgress } from "@mui/material";
import { PageLoader } from "../components/loader.component";

const PUBLIC_PATH = process.env.PUBLIC_URL;

export const OnboardingPage = () => {

    const navigate = useNavigate();
    const { user: { token } } = useSelector(state => state);
    const [user, setUser] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {

        if(!token) {
            dispatch(disconnect());
            navigate('/');
            return
        }

        API.getUser(token).then(user => { setUser(user); }).catch(() => { })
    }, [])

    const signOut = (event) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch(disconnect());
        navigate('/')
    }

    const importContactsFromGoogle = async () => {

        try {
            let { url } = await API.getAuthUrl();
            let childWindow = window.open(url);

            window.addEventListener('message', async event => {

              console.log("EVENT:", event)

                if(event.origin === API_URL || (process.env.NODE_ENV === "development" && ["http://127.0.0.1:8080", "http://localhost:8080"].indexOf(event.origin) >= 0)) {

                    const { authInfo, userInfo, source } = event.data;
                    let response = await API.addImport(token, authInfo, userInfo, source);

                    navigate('/');
                }
                
            }, false);

        } catch(error) {
            console.log("FAILED", error);
        }
    }

    return <>

    <div className="flex min-h-screen flex-1">
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
            {!user && <PageLoader />}


          {user && <div>
            <img className="h-10 w-auto select-none pointer-events-none" src={`${PUBLIC_PATH}/images/contaclidate@3x.png`} alt="Contaclidate" />
            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Hello {_.first(_.split(user.name, " "))},
            </h2>
            <p className="mt-2 text-sm leading-6 text-gray-700">
              Connect and Import your first Google account.
            </p>
          </div>}

          {user && <div className="mt-10">
            <div>
                <div className="space-y-6">
                  <button onClick={importContactsFromGoogle} type="submit" className="flex w-full justify-center rounded-md bg-brand px-3 py-1.5 text-sm font-semibold leading-6 text-slate-950 shadow-sm hover:bg-brand-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Select Account</button>
                </div>
                <p className="mt-4 mb-1 text-xs leading-6 text-gray-500">Contaclidate's use and transfer to any other app of information received from Google APIs will adhere to <a className="underline mb-4" href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank">Google API Services User Data Policy</a>, including the Limited Use requirements. Read Contaclidate's <a className="underline" href="https://www.contaclidate.com/privacy?utm_source=app&utm_medium=get-started" target="_blank">Privacy Policy</a> and <a className="underline" href="https://www.contaclidate.com/terms?utm_source=app&utm_medium=get-started">Terms of Use</a>.</p>
            </div>

            <div className="absolute bottom-8 sm:left-6 lg:left-20 xl:left-24">
                <button className="text-sm text-gray-400 hover:text-gray-700" onClick={signOut}>Sign Out</button>              
            </div>
          </div>}
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block bg-gray-50">
        <img className="absolute inset-0 h-full w-full object-cover" src={`${PUBLIC_PATH}/images/onboarding-small.webp`} alt="" />
        <span className="absolute bottom-4 right-4 text-xs font-medium text-slate-950 bg-gray-200/50 p-2 rounded invert">Photo by <a href="https://unsplash.com/@robertbye?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Robert Bye</a> on <a href="https://unsplash.com/photos/yellow-car-running-on-the-street-between-the-building-during-daytime-WTPp4wgourk?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></span>
      </div>
    </div>
  </>
}