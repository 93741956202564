import axios from 'axios';
import _ from 'lodash';

const API_URL = process.env.REACT_APP_API_URL;
const RELOGIN_CODE = 401;
const PUBLIC_PATH = process.env.PUBLIC_URL;


const genericErrorHandler = (error) => {
    if(error && error.isAxiosError && error.response) {
        if(error.response.status === 401) return 401;
    }
    
    console.log(error);
    return false;
}

class API
{
    static async signInUser(jwt) {
        try {
            let response = await axios(`${API_URL}/user/signin/google`, { headers: { Authorization: `Bearer ${jwt}`} })
            return response.data;

        } catch(error) {
            console.log(error);
            return false;
        }
    }

    static async getImports(jwt) {
        try {
            let response = await axios(`${API_URL}/user/imports`, { headers: { Authorization: `Bearer ${jwt}`} })
            return response.data;

        } catch(error) {
            console.log(error);
            return false;
        }
    }

    static async getUserImport(jwt, importId)
    {
        try {
            let response = await axios(`${API_URL}/user/import/${importId}`, { headers: { Authorization: `Bearer ${jwt}`} })
            return response.data;

        } catch(error) {
            console.log(error);
            return false;
        }
    }

    static async addImport(jwt, authInfo, userAuth, source) {
        try {
            let response = await axios(`${API_URL}/user/import`, { method: "POST", data: { authInfo, userAuth, source }, headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async updateAuthInfo(jwt, authInfo, userAuth, source, importId) {
        try {
            let response = await axios(`${API_URL}/user/import/auth`, { method: "PUT", data: { authInfo, userAuth, source, importId }, headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async getAuthUrl() {
        try {
            let response = await axios(`${API_URL}/auth/google/url?trg=${window.location.protocol}//${window.location.host}`);
            if(response.status === 200) return response.data;
            return null;
        } catch(error) {
            console.log(error);
        }
    }

    static async getContacts(jwt, classified = false, offset = 0, limit = 50) { 

        try {
            let response = await axios(`${API_URL}/user/contacts?offset=${offset}&limit=${limit}${classified ? '&type=clsf' : ''}`, { headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async getContactGroupedBy(jwt, group) {
        try {
            let response = await axios(`${API_URL}/user/contacts/group?by=${group}`, { headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async listUserContactsBy(jwt, group, keys) {
        try {
            let response = await axios(`${API_URL}/user/contacts/list/${group}/${_.join(keys, ',')}`, { headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async getContact(jwt, contactId) {
        try {
            let response = await axios(`${API_URL}/user/contact/${contactId}`, { headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async addContactTag(jwt, contactId, tag) {
        try {
            let response = await axios(`${API_URL}/contact/${contactId}/tag`, { method: "POST", data: { tag }, headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async removeContactTag(jwt, contactId, tag) {
        try {
            let response = await axios(`${API_URL}/contact/${contactId}/tag`, { method: "DELETE", data: { tag }, headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async addContactEmail(jwt, contactId, email) {
        try {
            let response = await axios(`${API_URL}/contact/${contactId}/email`, { method: "POST", data: { email }, headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async addContactPhone(jwt, contactId, phone) {
        try {
            let response = await axios(`${API_URL}/contact/${contactId}/phone`, { method: "POST", data: { phone }, headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async deleteContactEmail(jwt, contactId, email) {
        try {
            let response = await axios(`${API_URL}/contact/${contactId}/email`, { method: "DELETE", data: { email }, headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async deleteContactPhone(jwt, contactId, phone) {
        try {
            let response = await axios(`${API_URL}/contact/${contactId}/phone`, { method: "DELETE", data: { phone }, headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async makeContactEmailPrimary(jwt, contactId, email) {
        try {
            let response = await axios(`${API_URL}/contact/${contactId}/email`, { method: "PUT", data: { email }, headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async makeContactPhonePrimary(jwt, contactId, phone) {
        try {
            let response = await axios(`${API_URL}/contact/${contactId}/phone`, { method: "PUT", data: { phone }, headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async search(jwt, query, filters) {
        try {
            let response = await axios(`${API_URL}/contact/search`, { method: "POST", data: {query, filters}, headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async searchByName(jwt, query) {
        try {
            let response = await axios(`${API_URL}/contact/list?q=name:${encodeURI(query)}`, { headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async getRecentSearches(jwt) {
        try {
            let response = await axios(`${API_URL}/user/recent-searches`, { headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async listContactsByTags(jwt, query) {
        try {
            let response = await axios(`${API_URL}/contact/list?q=tag:${encodeURI(query)}`, { headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async listContactsByDomain(jwt, query) {
        try {
            let response = await axios(`${API_URL}/contact/list?q=domain:${encodeURI(query)}`, { headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async getContactInfo(jwt, contact) {
        try {
            let response = await axios(`${API_URL}/contact/info`, { method: "POST", data: { contact }, headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async mergeContact(jwt, source, target) {
        try {
            let response = await axios(`${API_URL}/contact/merge`, { method: "POST", data: { source, target }, headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async markAsModerated(jwt, contactId) {
        try {
            let response = await axios(`${API_URL}/contact/${contactId}/verify`, { method: "POST", headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async markAsDeleted(jwt, contactId) {
        try {
            let response = await axios(`${API_URL}/contact/${contactId}`, { method: "DELETE", headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async markAsNotDeleted(jwt, contactId) {
        try {
            let response = await axios(`${API_URL}/contact/${contactId}/touch`, { method: "GET", headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async updateContact(jwt, contactId, data)
    {
        try {
            let response = await axios(`${API_URL}/contact/${contactId}`, { method: "PUT", data, headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async getUser(jwt)
    {
        try {
            let response = await axios(`${API_URL}/user`, { method: "GET", headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async getUserTags(jwt)
    {
        try {
            let response = await axios(`${API_URL}/tags`, { method: "GET", headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async getDomainsStats(jwt, domains) 
    {
        try {
            let response = await axios(`${API_URL}/domains/stats`, { method: "POST", data: { domains }, headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async saveNote(jwt, contactId, note)
    {
        try {
            let response = await axios(`${API_URL}/notes/${contactId}`, { method: "POST", data: { note }, headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async getNotes(jwt, contactId) 
    {
        try {
            let response = await axios(`${API_URL}/notes/${contactId}`, { method: "GET", headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async updateNote(jwt, contactId, note, noteId) 
    {
        try {
            let response = await axios(`${API_URL}/notes/${contactId}`, { method: "PUT", data: { note, noteId }, headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async exportAsGoogleSheet(jwt, importId) {
        try {
            let response = await axios(`${API_URL}/export/googlesheet/${importId}`, { headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);

            let { url } = response.data;
            return url;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async exportAsCsv(jwt) 
    {
        try {
            let response = await axios(`${API_URL}/export/csv`, { responseType: 'blob', method: "GET", headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);

            let filename = _.last(response.headers['content-disposition'].split(';'));
            filename = (new URLSearchParams(_.replace(filename, /"|\s/g, ""))).get("filename")

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${filename}`);
            document.body.appendChild(link);
            link.click();

            return true;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async isGoogleSheetEnabled(jwt, importId)
    {
        try {
            let response = await axios(`${API_URL}/google/sheet/status/${importId}`, { method: "GET", headers: { Authorization: `Bearer ${jwt}`} });
            if(response.status !== 200) throw new Error(`API error. Status code ${response.status}`);
            return response.data;
        } catch(error) { return genericErrorHandler(error); }
    }

    static async getGoogleSheetAuthUrl(jwt, importId) {
        try {
            let response = await axios(`${API_URL}/google/sheet/authurl/${importId}?trg=${window.location.protocol}//${window.location.host}`, {headers: { Authorization: `Bearer ${jwt}`}});
            if(response.status === 200) return response.data;
            return null;
        } catch(error) {
            console.log(error);
        }
    }

    static getImageDensity(filename) {
        return `${PUBLIC_PATH}/images/${filename}.webp, ${PUBLIC_PATH}/images/${filename}@2x.webp 2x, ${PUBLIC_PATH}/images/${filename}@3x.webp 3x`
    }

    static getImagePath(filename) {
        return `${PUBLIC_PATH}/images/${filename}`
    }

}

class StrHelper {
    static ucFirst(word) {
        return _.join(_.map(_.split(word, ' '), w => w.charAt(0).toUpperCase() + w.slice(1)), ' ');
    }

    static regExEscape(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    static validateEmail(mail) {
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail);
    }

    static validatePhone(number) {
		let re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/mgi;
		return re.test(number);
    }
}

class ContactHelper {

    static getPrimaryName = (contact) => {
        let name = _.filter(contact.names, {primary: true});
        if(_.isEmpty(name)) return '';

        name = _.first(name);
        return `${StrHelper.ucFirst(name.name)}`;
    }

    static isPhoneNumbersAvailable = (contact) => {
        return !_.isEmpty(contact.numbers);
    }

    static getPrimaryPhoneNumber = (contact, link = false, returnAsObject = false) => {
        let phone = _.first(_.filter(contact.numbers, {primary: true})); 
        if(phone) {
            if(returnAsObject) return phone;
            if(link) return phone.number.rfc3966;
            return phone.number.international;
        }
        return '';
    }

    static getPhoneNumberCountryCode = (contact) => {
        let phone = ContactHelper.getPhoneNumber(contact, true); 
        if(phone && phone.regionCode) return phone.regionCode.toLowerCase();
        return null;
    }

    static getPhoneNumber = (contact, returnAsObject = false) => {
        let number = ContactHelper.getPrimaryPhoneNumber(contact, false, returnAsObject);
        if(_.isEmpty(number)) number = ContactHelper.getNonPrimaryPhoneNumber(contact, returnAsObject);
        return number;
    }

    static getNonPrimaryPhoneNumber = (contact, returnAsObject = false) => {
        let numbers = ContactHelper.getNonPrimaryPhoneNumbers(contact, returnAsObject);
        if(!_.isEmpty(numbers)) return _.first(numbers);
        return null;
    }

    static getNonPrimaryPhoneNumbers = (contact, returnAsObject  = false) => {
        return _.map(_.filter(contact.numbers, {primary: false}), numberInfo => {
            return returnAsObject ? numberInfo : numberInfo.number.international;
        });
    }

    static getDomain = contact => {
        return ContactHelper.formatDomain(_.first(contact.domains));
    }

    static formatDomain = domain => _.replace(domain, new RegExp('_', 'g'), ".");

    static isEmailExist = contact => {
        return !_.isEmpty(contact.emails);
    }

    static getPrimaryEmailAddress = (contact) => {
        let email = _.first(_.filter(contact.emails, {primary: true})); 
        if(email) return email.value;
        return ' ';
    }

    static isEmailAddressesAvaialble = (contact) => {
        return !_.isEmpty(contact.emails);
    }

    static getNonPrimaryEmailAddresses = (contact) => {
        return _.map(_.filter(contact.emails, {primary: false}), addressInfo => addressInfo.value);
    }

    static getTitle = (contact) => {
        if(contact.title) return contact.title;
        return '';
    }
}

class TagsHelper 
{
    static getTagAsLabel = (tag) => {
        if(tag && tag.length <= 3) return tag.toUpperCase();
        return StrHelper.ucFirst(tag);
    }
}

class NavigationHelper 
{
    static isUrlIncludeContactId = (path) => {
        const CONTACT_URL_REGEX = new RegExp('^/contact/[a-z0-9]?', 'i');
        return (CONTACT_URL_REGEX.test(path));
      }
}

class NotificationHelper
{
    static INFO = "info";
    static WARN = "warn";
    static ERROR = "error";
    static SUCCESSS = "success";

    static buildNotification = (title, message, type = NotificationHelper.INFO, autoFade = false) =>
    {
        return {
            autoFade: autoFade,
            show: true,
            type: type,
            title: title,
            message: message
        }
    }
}

export {
    NavigationHelper, ContactHelper, TagsHelper, StrHelper, NotificationHelper, API, RELOGIN_CODE, API_URL
}