import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { CommandBox, COMMANDS } from '../components/commandBox.component';
import { Tooltip } from '../components/tooltip.component';
import { clearShortcut } from '../store/slices/shortcuts.slice';
import { API } from '../utils/api';
import { ContactSection, TABS } from './sections/contact.section';
import { selectContact, setShowContactModal, setShowNotesEditor } from '../store/slices/ui.slice';
import _ from 'lodash';
import { getDefaultView } from './main.page';
import { Analytics } from '../utils/analytics';



export const ContactPage = () => {

    const [contact, setContact] = useState({});
    const [loading, setLoading] = useState(false);
    
    const [showCmdBox, setShowCmdBox] = useState(false);
    const [activeTab, setActiveTab] = useState(TABS.DETAILS)

    const params = useParams();
    const dispatch = useDispatch();

    const location = useLocation();
    const navigate = useNavigate();
    
    const { ui: { selectedContact, contactsList, pathHistory, showContactModal }, user: { token }, shortcuts: { key, meta, alt, code } } = useSelector(state => state);

    useEffect(() => {

        if(!showContactModal) {
            return;
        }

        if(showCmdBox && key === 'escape') {
            return setShowCmdBox(false);
        }

        if(showCmdBox) return;

        if(key === 'tab')
        {
            if(activeTab === TABS.NOTES) {
                setActiveTab(TABS.DETAILS);
                dispatch(clearShortcut());
                return;
            }
            if(activeTab === TABS.DETAILS) {
                setActiveTab(TABS.NOTES);
                dispatch(clearShortcut());
                return;
            }
        }

        if(key === 'escape') {

            if(showCmdBox) {
                setShowCmdBox(false);
            }
            else {               
                closeContactModal();
            }

            dispatch(clearShortcut());
        }

        if(meta) 
        {
            if(code === "keye") return onNewEmail();
            if(alt && code === "keyn") return onNewPhone();

            if(key === "enter")
            {
                dispatch(clearShortcut());
                markAsModerated().then(contact => {
                    dispatch(selectContact(contact));
                }).catch(error => {
                    console.log("---<>----", error);
                })

                return;
            }
        }

        if(!meta && alt && code === "keyn") {
            dispatch(clearShortcut());
            dispatch(setShowNotesEditor(true));
            return;
        }

        if(code === 'keyt') {
            dispatch(clearShortcut());
            setShowCmdBox(COMMANDS.Tags);
        }

        if(code === 'keym') {
            dispatch(clearShortcut());
            setShowCmdBox(COMMANDS.Merge);
        }

        if(code === 'keye') {
            onEditClick();
        }

        if(code === 'keyd') {
            dispatch(clearShortcut());

            if(showCmdBox === COMMANDS.Delete) {
                setShowCmdBox(false);
            
                API.markAsDeleted(token, selectedContact.id).then(contact => {
                    if(contact.deleted) dispatch(selectContact(contact));
                }).catch(error => {
                    console.log("Failed!");
                }) 

                return;
            }

            setShowCmdBox(COMMANDS.Delete);
        }

    }, [key])

    const onNewEmail = () => {
        dispatch(clearShortcut());
        setShowCmdBox(COMMANDS.Email);
    }

    const onNewPhone = () => {
        dispatch(clearShortcut());
        setShowCmdBox(COMMANDS.Number);
    }

    const onTabClick = tab => setActiveTab(tab);

    const closeContactModal = () => {
        dispatch(setShowContactModal(false));
        navigate(-1);
    }

    const markAsModerated = async () => {
        return API.markAsModerated(token, selectedContact.id);
    }

    const onRestoreClick = async() => {
        let contact = await API.markAsNotDeleted(token, selectedContact.id);
        dispatch(selectContact(contact));
    }

    const loadContact = (contactId = null) => {
                
        let { id } = params;

        API.getContact(token, contactId ? contactId : id).then(contact => {
            dispatch(selectContact(contact));
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        Analytics.trackEvent('View Contact');
        if(selectedContact === null) return loadContact();

        setContact(selectedContact);
        setLoading(false);

    }, [selectedContact])

    const onEditClick = () => {
        setShowCmdBox(COMMANDS.Name);
    }

    return <div>
        <section>
            <main>
                {!loading && <div>
                    <ContactSection 
                        contact={contact} onEditClick={onEditClick} 
                        showTags={() => setShowCmdBox(COMMANDS.Tags)}
                        onDeleteClick={() => setShowCmdBox(COMMANDS.Delete)}
                        onMergeClick={() => setShowCmdBox(COMMANDS.Merge)}
                        onRestoreClick={onRestoreClick}
                        onVerifiedClick={() => markAsModerated()}
                        onNewEmailClick={() => onNewEmail()}
                        onNewPhoneClick={() => onNewPhone()}
                        onManageTagsClick={() => setShowCmdBox(COMMANDS.Tags)}
                        onCloseClicked={() => closeContactModal()} setActiveTab={onTabClick} activeTab={activeTab}
                    />
                </div>}
            </main>
        </section>

        {showCmdBox && <CommandBox command={showCmdBox} closeCmdBox={() => setShowCmdBox(false)} />}
    </div>;
}