import { ArrowRightIcon, ArrowSmallLeftIcon, ArrowSmallRightIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import numeral from "numeral";
import { useMemo, useState } from "react";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { CommandBox, COMMANDS } from "../components/commandBox.component";
import { PageLoader } from "../components/loader.component";
import { Tag, TagsList } from "../components/tag.component";
import { clearShortcut } from "../store/slices/shortcuts.slice";
import { selectContact } from "../store/slices/ui.slice";
import { API, ContactHelper, TagsHelper } from "../utils/api";
import { PageBase } from "./base.page"
import { PageBody } from "./body.page";
import { PageHeader } from "./header.page"
import { Notification } from "../components/notification.component";

const PUBLIC_PATH = process.env.PUBLIC_URL;

let limit = 50;
let offset = 0;

export const ClassifyPage = ({}) => {
    
    const { user: { token }, shortcuts: { key, meta, code }, ui: { selectedContact } } = useSelector(state => state);
    const dispatch = useDispatch();
    const searchbar = useRef(null);
    const [contacts, setContacts] = useState([]) ;
    const [loading, setLoading] = useState(true);
    const [showCmdBox, setShowCmdBox] = useState(false);
    const [index, setIndex] = useState(0);
    const [metadata, setMetadata] = useState({});

    useEffect(() => {
        if(key === '/') {
          searchbar.current.focus();
          dispatch(clearShortcut());
        }

        if(key === 'escape') {
            if(showCmdBox) {
                setShowCmdBox(false);
                dispatch(clearShortcut());
            }
        }

        if(key === 'enter' && meta)
        {
            dispatch(clearShortcut());
            markAsVerified();
            dispatch(clearShortcut());
        }

        if(code === 'keyd') {
            onDeleteClick();
            dispatch(clearShortcut());
        }

        if(code === 'keyt') {
            onManageTagsClick();
            dispatch(clearShortcut());
        }

        if(code === 'keym') {
            onMergeClick();
            dispatch(clearShortcut());
        }

        if(code === 'keyn') {
            onAddNoteClick();
            dispatch(clearShortcut());
        }

        if(code === 'keye') {
            onEditNameClick();
            dispatch(clearShortcut());
        }

        if(code === 'keyv') {
            onOpenDomainClick();
            dispatch(clearShortcut());
        }

        if(key === 'arrowright') {
            next();
            dispatch(clearShortcut());
        }

        if(key === 'arrowleft') {
            previous();
            dispatch(clearShortcut());
        }

      }, [key, code])

    useEffect(() => loadContacts(), []);

    useEffect(() => {
        if(!contacts || !contacts.contacts || _.isEmpty(contacts.contacts)) return null;
        dispatch(selectContact(contacts.contacts[index]));
    }, [index, contacts])

    useEffect(() => {
        if(!selectedContact) return;
        // API.getContactInfo(token, selectedContact).then(setMetadata).catch(error => {

        // })
    }, [selectedContact]);

    const loadContacts = () => {
        API.getContacts(token, "notClsf", offset, limit).then(contacts => {
            setLoading(false);
            setContacts(contacts);
        }).catch(error => {
            console.error("FAIELD")
        })
    }

    const onCommandClosed = command => {
        if(command === COMMANDS.Delete) {
            loadContacts();
        }

        setShowCmdBox(false);
    }

    const onOpenDomainClick = () => {
        if(showCmdBox) return;
        const windowFeatures =`left=${(window.innerWidth - window.innerWidth * .8) / 2},top=${(window.innerHeight - window.innerHeight * .8) / 2},width=${(window.innerWidth * .8)},height=${(parseInt(window.innerWidth * .56))}`;
        window.open(`https://${ContactHelper.getDomain(selectedContact)}`, `Inspect Contact Domain`, windowFeatures);
    }

    const onDeleteClick = () => {
        if(showCmdBox) return;
        setShowCmdBox(COMMANDS.Delete);
    }

    const onManageTagsClick = () => {
        if(showCmdBox) return;
        setShowCmdBox(COMMANDS.Tags);
    }

    const onMergeClick = () => {
        if(showCmdBox) return;
        setShowCmdBox(COMMANDS.Merge);
    }

    const markAsVerified = () => {
        API.markAsModerated(token, selectedContact.id).then(contact => {
            loadContacts();
        }).catch(error => {
            console.log("---<>----", error);
        });
    }

    const onAddNoteClick = () => {
        if(showCmdBox) return;
        setShowCmdBox(COMMANDS.Note)
    }

    const onEditNameClick = () => {
        if(showCmdBox) return;
        setShowCmdBox(COMMANDS.Name);
    }

    const next = () => {
        if(showCmdBox) return;
        if(index === (contacts.contacts.length - 1)) {
            offset = offset + limit;
            loadContacts();
            setIndex(0);
            return;
        }

        setIndex((index+1));
    }

    const previous = () => {
        if(showCmdBox) return;
        if(index === 0) return;
        setIndex(index-1);
    }

    return <>
        <PageBase>
            <PageHeader ref={searchbar} />
            <PageBody>
                <header className="text-center">
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100 my-6">
                        <svg className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122" /></svg>
                    </div>
                    <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Classify Contacts</h1>
                    <p className="mt-1 truncate text-sm text-gray-500">Tag your contacts and mark them verified to validate your network.</p>
                </header>

                {loading && <PageLoader label="Loading Contacts" />}

                {!loading && selectedContact && <div className="bg-gray-100 py-8 mt-16 mb-4">

                    <ul className="text-center flex flex-col items-center justify-center w-full py-4 gap-x-4">
                        <li className="w-1/3 min-w-[18rem] flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow">
                            <div className="flex flex-1 flex-col p-8">
                                <img className="mx-auto h-24 w-24 flex-shrink-0 rounded-full bg-gray-50 ring-1 ring-slate-400 object-cover" src={selectedContact.photo} />
                                <h3 className="mt-6 text-sm font-medium text-gray-900">{ContactHelper.getPrimaryName(selectedContact)}</h3>
                                <dl className="mt-1 flex flex-grow flex-col justify-between">
                                    <dd className="text-sm text-gray-500">{ContactHelper.getPrimaryEmailAddress(selectedContact)}</dd>
                                </dl>
                                <dl className="mt-1 flex flex-grow flex-col justify-between">
                                    {!_.isEmpty(selectedContact.domains) && <div className="mt-4 flex items-center justify-center">
                                        <span className="inline-flex items-center text-gray-500 sm:text-sm w-4 mr-2">
                                            <figure className="w-full">
                                                <img src={`https://www.google.com/s2/favicons?domain=${_.first(selectedContact.domains)}&sz=256`} />
                                            </figure>
                                        </span>
                                        <div className="flex space-x-2 items-center">
                                            <a className="text-sm no-underline hover:underline" target={"_blank"} href={`https://${ContactHelper.getDomain(selectedContact)}`}>{ContactHelper.getDomain(selectedContact)}</a>
                                            {/* <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">V</span> */}
                                        </div>
                                    </div>}
                                    {!_.isEmpty(selectedContact.numbers) && <div className="mt-4 flex items-center justify-center">
                                        <div className="flex space-x-2 items-center">
                                            <span className="inline-flex items-center text-gray-500 sm:text-sm w-4 mr-2">
                                                <figure className="w-full">
                                                <img src={`${process.env.PUBLIC_URL}/images/flags/${ContactHelper.getPhoneNumberCountryCode(selectedContact)}.svg`} />
                                                </figure>
                                            </span>
                                            <span className="text-sm no-underline slashed-zero">{ContactHelper.getPhoneNumber(selectedContact)}</span>
                                        </div>
                                        
                                    </div>}
                                </dl>
                                <TagsList className="mt-6">
                                    {_.map(selectedContact.tags, tag => <Tag>{TagsHelper.getTagAsLabel(tag)}</Tag>)}
                                </TagsList>
                            </div>
                            {!_.isEmpty(selectedContact.source) && <div className="py-1 bg-gray-50/50">
                                {selectedContact.source && selectedContact.source.label && <span className="text-xs text-gray-700 leading-7">Source: {`${selectedContact.source.label} <${selectedContact.source.email}>`}</span>}
                                {!selectedContact.source.email && <span className="text-xs text-gray-700 leading-7">Source: {`${selectedContact.source}`}</span>}
                            </div>}
                        </li>
                        {metadata && metadata.linkedin &&  <li className="w-1/3">
                            {/* <div className="w-full h-1/2 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow">Item 1</div> */}

                            <div className="col-span-1 flex rounded-md shadow-sm mt-2 cursor-pointer hover:shadow">
                                <div className={'flex w-24 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white bg-linkedin overflow-hidden'}>
                                    <img src={metadata.linkedin.image.src} className="w-full h-full object-cover" />
                                
                                    {/* <img src={`${PUBLIC_PATH}/images/social/linkedin-icon.svg`} className="w-11 h-11" /> */}
                                </div>
                                <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                                    <div className="flex-1 truncate px-4 py-2 text-sm text-left">
                                        <a href={metadata.linkedin.link} target="_blank" className="font-medium text-gray-900 whitespace-normal hover:text-gray-600">{metadata.linkedin.title}</a>
                                        <p className="text-gray-500 text-xs whitespace-normal w-full mt-2 flex items-center">
                                            <img src={`${PUBLIC_PATH}/images/social/linkedin-icon.svg`} className="w-3 h-3" />&nbsp;LinkedIn
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="flex-shrink-0 pr-2 mt-2">
                                <button type="button" className="rounded-md bg-white px-2.5 py-1.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Connect LinkedIn</button>
                            </div> */}
                            
                        </li>}
                    </ul>
                </div>}

                {!loading && selectedContact && <nav className="flex justify-center w-full mb-24 relative">
                    {index > 0 && <button type="submit" className="absolute left-0 top-0 bottom-0 mr-4 rounded-md bg-white px-3 py-2 inline-flex items-center gap-x-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400" onClick={() => previous()}>
                        <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"><ArrowSmallLeftIcon className="w-3 h-3"/></span> Previous 
                    </button>}

                    <button type="submit" className="justify-self-start rounded-md bg-white px-3 py-2 inline-flex items-center gap-x-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400" onClick={() => onDeleteClick()}>
                        Delete <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">D</span>
                    </button>

                    <button type="submit" className="ml-4 rounded-md bg-white px-3 py-2 inline-flex items-center gap-x-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400" onClick={() => onMergeClick()}>
                        Merge Contact <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">M</span>
                    </button>

                    <button type="submit" className="ml-4 rounded-md bg-white px-3 py-2 inline-flex items-center gap-x-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400" onClick={() => onEditNameClick()}>
                        Edit Details <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">E</span>
                    </button>

                    <button type="submit" className="ml-4 rounded-md bg-white px-3 py-2 inline-flex items-center gap-x-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400" onClick={() => onAddNoteClick()}>
                        Add Note <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">N</span>
                    </button>
                    
                    <button type="submit" className="ml-4 rounded-md bg-white px-3 py-2 inline-flex items-center gap-x-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400" onClick={() => onManageTagsClick()}>
                        Manage Tags <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">T</span>
                    </button>

                    <button type="button" className="ml-4 inline-flex items-center gap-x-1.5 rounded-md bg-brand hover:bg-brand-hover px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm" onClick={markAsVerified}>
                        Mark as Verified <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">⌘⏎</span>
                    </button>

                    <button type="submit" className="absolute right-0 top-0 bottom-0 rounded-md bg-white px-3 py-2 inline-flex items-center gap-x-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400" onClick={() => next()}>
                        Next <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"><ArrowSmallRightIcon className="w-3 h-3"/></span>
                    </button>
                </nav>}

                <div className="grid grid-1 gap-5 mt-8 px-24 items-center justify-center">
                    <div key={"asdasd"} className="max-w-sm overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 text-center">
                        <dt className="truncate text-sm font-medium text-gray-500">Contacts Remained to Classify</dt>
                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{numeral(contacts.size).format("0,0")}</dd>
                    </div>
                </div>

                {showCmdBox && <CommandBox command={showCmdBox} closeCmdBox={onCommandClosed} />}
                <Notification />
            </PageBody>
        </PageBase>
    </>
}