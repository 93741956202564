import { ArrowSmallDownIcon } from "@heroicons/react/20/solid";
import { ChevronDoubleDownIcon } from "@heroicons/react/24/outline";
import _ from "lodash";

export const ImportAccount = ({details, source, progress}) => {

    if(!details) return null;

    return <div className='group relative flex items-center space-x-3 rounded-lg bg-white px-2 py-2 border border-gray-50 cursor-default hover:shadow'>
        <div className="flex-shrink-0">
            <img className="h-8 w-8 rounded-full" src={details.picture} alt="" loading="lazy" />
        </div>
        <div className="min-w-0 flex-1">
            <a href="#" className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="text-sm font-medium text-gray-900">{details.name}</p>
                <p className="truncate text-xs text-gray-500 capitalize">{source} Account {progress === 100 ? '' : 'Importing..'}</p>
            </a>
        </div>
        <div className="absolute right-4">
            <ChevronDoubleDownIcon className="w-5 h-5 text-gray-500 group-hover:text-gray-700 group-focus:text-blue-200" />
        </div>
    </div>   
}

export const ImportOption = ({details, source, progress, state, onClick}) => {

    if(!details) return null;

    return <li className="w-full relative flex items-center space-x-2 bg-white px-2 py-2 hover:bg-gray-50" onClick={onClick}>
        <div className="flex-shrink-0">
            <img className="h-8 w-8 rounded-full" src={details.picture} alt="" />
          </div>
          <div className="min-w-0 flex-1">
            <a href="#" className="focus:outline-none">
              <span className="absolute inset-0" aria-hidden="true" />
              <p className="text-xs font-medium text-gray-900">{details.name}</p>
              <p className="truncate text-xs text-gray-500">{details.email}</p>
            </a>
          </div>
        {/* <section>
            
            <div>
                <h2>{details.name}</h2>
                <p>{details.email} ● <span>{source}</span> ● {progress === 100 ? 'Ready' : 'In Progress'}</p>
            </div>
        </section> */}
        {state === 0 && <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"><svg className="animate-ping h-1.5 w-1.5 fill-yellow-500" viewBox="0 0 6 6" aria-hidden="true"><circle cx={3} cy={3} r={3} /></svg>Queued</span>}
        {(state > 0 && state < 4) && <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"><svg className="animate-ping h-1.5 w-1.5 fill-yellow-500" viewBox="0 0 6 6" aria-hidden="true"><circle cx={3} cy={3} r={3} /></svg>In Progress</span>}
        {state === 4 && <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"><svg className="h-1.5 w-1.5 fill-green-500" viewBox="0 0 6 6" aria-hidden="true"><circle cx={3} cy={3} r={3} /></svg>Sycned</span>}
        {state > 90 && <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"><svg className="h-1.5 w-1.5 fill-red-500" viewBox="0 0 6 6" aria-hidden="true"><circle cx={3} cy={3} r={3} /></svg>Error</span>}
    </li>
}