
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API, API_URL, ContactHelper, TagsHelper } from "../../utils/api";
import _ from 'lodash';
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Tag, TagsList } from "../../components/tag.component";
import { selectContact } from "../../store/slices/ui.slice";
import { SectionHeader } from "./sectionHeader";
import { ArrowDownTrayIcon, ShieldCheckIcon, TableCellsIcon } from "@heroicons/react/20/solid";
import numeral from "numeral";
import classNames from "classnames";
import { CircularProgress } from "@mui/material";
import { PageLoader } from "../../components/loader.component";
import { EmptyState } from "../../components/empty.component";

const PUBLIC_PATH = process.env.PUBLIC_URL;

const people = [
    {
      name: 'Lindsay Walton',
      title: 'Front-end Developer',
      department: 'Optimization',
      email: 'lindsay.walton@example.com',
      role: 'Member',
      image:
        'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    // More people...
  ]

export const Lean = ({importId, tag, domain, triggerContactsUpdate}) => {

    const { user: { token }, ui: { selectedContact } } = useSelector(state => state);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [contacts, setContacts] = useState([]);
    const [size, setSize] = useState(null);
    const [length, setLength] = useState(0);
    const [limit, setLimit] = useState(50);
    const [offset, setOffset] = useState(0);

    const [exportedUrl, setExportedUrl] = useState(null);
    const [sheetLoading, setSheetLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const sheetButtonLabel = useMemo(() => {
        if(exportedUrl) return "Open on Google Drive";
        if(sheetLoading && !exportedUrl) return "Exporting...";
        return "Export"
    }, [sheetLoading, exportedUrl])

    const [csvLoading, setCsvLoading] = useState(false);
    const csvButtonLabel = useMemo(() => csvLoading ? "Exporting..." : "Export", [csvLoading])

    const enableGoogleSheet = () => {
        API.getGoogleSheetAuthUrl(token, importId).then(async url => {

            let childWindow = window.open(url);

            window.addEventListener('message', async event => {

                childWindow.close();

                if(event.origin === API_URL) {

                    const { authInfo, userInfo, source } = event.data;

                    let update = await API.updateAuthInfo(token, authInfo, userInfo, source, importId);
                    if(update) return exportAsGoogleSheet();
                }
                
            }, false);
        }).then(error => {

        })
    }

    useEffect(() => loadContacts(), [triggerContactsUpdate])

    const exportAsGoogleSheet = () => {

        if(exportedUrl) return window.open(exportedUrl);

        setExportedUrl(null);
        API.isGoogleSheetEnabled(token, importId).then(async response => {
            if(!response) return enableGoogleSheet();
            let url = await API.exportAsGoogleSheet(token, importId);
            setExportedUrl(url);
            setSheetLoading(false);
        }).then(error => {
            console.error("Failed to export to Google Sheet:", error);
            setSheetLoading(false);
        })
    }

    const donwloadSheetClicked = () => {
        setSheetLoading(true);

        exportAsGoogleSheet();
    }

    const downloadCsvClicked = () => {
        setCsvLoading(true);

        API.exportAsCsv(token).then(response => {
            setCsvLoading(false);
        }).then(error => {
            setCsvLoading(false);
        })
    }

    const buildList = (contacts) => {
        let alphabetContacts = {};

        _.forEach(contacts, contact => {
            let name = ContactHelper.getPrimaryName(contact);
            let char = name.charAt(0).toUpperCase();

            if(!alphabetContacts[char]) alphabetContacts[char] = [];
            alphabetContacts[char].push(contact);
        });

        return alphabetContacts;
    }

    const loadContacts = () => {

        if(tag) {

            API.listContactsByTags(token, tag).then(response => {

                let contacts = buildList(response);

                setLoading(false);
                if(!response) return;

                setContacts(contacts);
                setSize(response.length);
                setLength(response.length);
            }).catch(error => {});

            return;
        }

        if(domain)
        {
            API.listContactsByDomain(token, domain).then(response => {

                let contacts = buildList(response);
                
                setLoading(false);
                if(!response) return;

                setContacts(contacts);
                setSize(response.length);
                setLength(response.length);

            }).catch(error => {

            })

            return;
        }

        API.getContacts(token, false, offset, limit).then(contacts => {

            let alphabetContacts = buildList(contacts.contacts);

            setLoading(false);
            if(!contacts || !contacts.contacts) return;

            setContacts(alphabetContacts);
            setSize(contacts.size);
            setLength(contacts.contacts.length);
        })
    }

    useEffect(() => {
        if(!selectedContact) return;

        let name = ContactHelper.getPrimaryName(selectedContact);
        let char = name.charAt(0).toUpperCase();

        if(contacts[char])
        {
            let indexOf = _.findIndex(contacts[char], {id: selectedContact.id});

            if(indexOf >= 0) {
                let cloned = _.cloneDeep(contacts);

                if(selectedContact.deleted) {
                    _.pullAt(cloned[char], indexOf);
                } else {
                    cloned[char][indexOf] = selectedContact;
                }

                setContacts(cloned);
            }
        }

    }, [selectedContact])

    useEffect(() => {
        loadContacts();
    }, [offset])

    useEffect(() => {
        loadContacts();
    }, [tag, domain])

    useEffect(() => {
        if(!importId) return;

        API.getUserImport(token, importId).then(data => {
            setData(data);
            // setLoading(false);
        }).catch(error => {

        });

    }, [importId]);

    const nextAvailable = useMemo(() => ((offset + length) < size), [offset, length]);
    const previousAvailable = useMemo(() => offset > 0);
    const totalPages = useMemo(() => Math.ceil(size / limit), [offset, length]);

    const onSelectContact = contact => {
        dispatch(selectContact(contact));
        navigate(`/contact/${contact.id}`);
    }

    const onPageClick = page => {
        console.log(page);

        setOffset(length * page);
    }

    const nextPage = () => {
        setOffset((offset + length));
    }

    const previousPage = () => {
        setOffset((offset - limit));
    }

    const title = useMemo(() => {
        if(tag) return `#${TagsHelper.getTagAsLabel(tag)}`;
        if(domain) return `${ContactHelper.formatDomain(domain)}`;

        return `Contacts`
    }, [tag, domain]);

    const desc = useMemo(() => {
        if(tag) return "Contacts below tagged by the above label";
        if(domain) return "All Related Contacts with Domain";

        return "Browse and Manage Your Network Connections"
    }, [tag, domain])

    return <>
        <SectionHeader title={title} desc={desc} buttons={loading || tag ? [] : [
        //     {
        //     label: "Export to GSheet",
        //     icon: <ArrowDownTrayIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />,
        //     onClick: donwloadSheetClicked
        // }, 
        {
            label: "Export CSV",
            icon: <TableCellsIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />,
            onClick: downloadCsvClicked
        }]}/>
        {loading && <PageLoader label="Loading Contacts"/>}
        {!loading && <div className=''>
            <main className="mb-48">
                {_.isEmpty(contacts) && <EmptyState title="No Contacts" message={tag ? `No contacts available for #${TagsHelper.getTagAsLabel(tag)}` : `Change filter or import an account`}/>}
                {contacts && Object.keys(contacts).length > 0 && <div className="px-0">
                    <div className="mt-8 flow-root ">
                        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <table className="min-w-full border-b relative ">
                                {_.map(contacts, (persons, group) => {
                                    return <Fragment key={group}>
                                        <thead>
                                            {Object.keys(contacts).indexOf(group) === 0 && <tr>
                                                <th scope="col" className="sticky top-9 drop-shadow-sm bg-white py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-2">Name</th>
                                                <th scope="col" className="sticky top-9 drop-shadow-sm bg-white px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Tags</th>
                                                <th scope="col" className="sticky top-9 drop-shadow-sm bg-white px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                                                <th scope="col" className="sticky top-9 drop-shadow-sm bg-white px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Phone</th>
                                                <th scope="col" className="sticky top-9 drop-shadow-sm bg-white px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Verified</th>
                                                <th scope="col" className="sticky top-9 drop-shadow-sm bg-white px-3 py-3.5 text-left text-sm font-semibold text-gray-900 z-10">Actions</th>
                                            </tr>}
                                            <tr><th className="sticky -top-1 px-6 py-2 bg-gray-50 z-10 text-slate-950 drop-shadow-sm text-left" colSpan={6}>
                                                <div className="flex justify-between items-center">
                                                    {group}
                                                    <span className="text-xs font-mono font-normal">{`${numeral(persons.length).format('0,0')}`} Contacts</span>    
                                                </div>
                                                
                                            </th></tr>
                                        </thead>
                                        <tbody className="divide-y divide-slate-100 bg-white">
                                            {persons.map((person) => (
                                                <tr key={ContactHelper.getPrimaryEmailAddress(person)} className="divide-x divide-gray-200">
                                                    <td className="whitespace-nowrap py-2 pl-10 pr-3 text-sm sm:pl-2">
                                                    <div className="flex items-center">
                                                        <div className="h-10 w-10 flex-shrink-0">
                                                        <img className="h-10 w-10 rounded-full" src={person.photo} alt="" />
                                                        </div>
                                                        <div className="ml-4">
                                                        <div className="font-medium text-gray-900">
                                                            <span className="cursor-pointer max-w-xs" onClick={() => onSelectContact(person)}>{ContactHelper.getPrimaryName(person)}</span>
                                                        </div>
                                                        <div className="mt-1 text-gray-500 max-w-xs truncate">{ContactHelper.getTitle(person)}</div>
                                                        </div>
                                                    </div>
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                                    <div className="text-gray-900">
                                                        <TagsList>{person && person.tags && person.tags.map(tag => <Tag key={tag}>{TagsHelper.getTagAsLabel(tag)}</Tag>)}</TagsList>
                                                    </div>
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                                        <div className="mt-1 text-gray-500">{ContactHelper.getPrimaryEmailAddress(person)}</div>
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{ContactHelper.getPhoneNumber(person)}</td>
                                                    <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                                        {person.moderated && <div className="inline-flex gap-x-2 mt-2">
                                                            <ShieldCheckIcon className="w-5 h-5 text-green-700"/>Verified
                                                        </div>}
                                                    </td>
                                                    <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-center text-sm font-medium sm:pr-2" >
                                                        <a href="#" className="text-slate-700 hover:text-slate-950" onClick={e => {
                                                            e.preventDefault(); e.stopPropagation();
                                                            onSelectContact(person);
                                                        }}>Manage</a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Fragment>
                                })}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>}

                {!_.isEmpty(contacts) && <nav className="grid grid-cols-3 items-center justify-between bg-white px-4 py-3 sm:px-2" aria-label="Pagination">
                    <div className="block">
                        <p className="text-sm text-gray-700">Showing <span className="font-medium">{offset}</span> to <span className="font-medium">{offset + length}</span> of{' '}<span className="font-medium">{numeral(size).format("0,0")}</span> results</p>
                    </div>
                    <nav className="isolate inline-flex -space-x-px flex-wrap justify-center" aria-label="Pagination">
                        {_.map(_.range(0, totalPages), page => {
                            return <button key={page} className={classNames(["block items-center px-2 min-w-[30px] text-center py-2 mb-2 text-xs font-semibold ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0", (page === offset / limit ? "text-white bg-slate-800 hover:bg-slate-950" : "text-gray-900 hover:bg-gray-50")])} onClick={() => onPageClick(page)}>{page+1}</button>;
                        })}
                    </nav>
                    <div className="flex justify-between sm:justify-end">
                        {previousAvailable && <a href="#" className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0" onClick={previousPage}>Previous</a>}
                        {nextAvailable && <a href="#" className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0" onClick={nextPage}>Next</a>}
                    </div>
                </nav>}
            </main> 
        </div>}
    </>
};