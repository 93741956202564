import _ from "lodash"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { EmptyState } from "../components/empty.component"
import { PageLoader } from "../components/loader.component"
import { Tag, TagsList } from "../components/tag.component"
import { clearShortcut } from "../store/slices/shortcuts.slice"
import { API, TagsHelper } from "../utils/api"
import { PageBase } from "./base.page"
import { PageBody } from "./body.page"
import { ContactPage } from "./contact.page"
import { PageHeader } from "./header.page"
import { SectionHeader } from "./sections/sectionHeader"

export const TagsPage = ({}) => {

    const { user: { isConnected, token }, shortcuts: { key, meta }, ui: { selectedContact, highlightContact, showContactModal } } = useSelector(state => state);
    
    const searchbar = useRef(null);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [tags, setTags] = useState([]);

    useEffect(() => {
        if(key === '/') {
          searchbar.current.focus();
          dispatch(clearShortcut());
        }
      }, [key])

    useEffect(() => {

        API.getUserTags(token).then(tags => {
            setTags(tags);
            setLoading(false);
        }).catch(error => {

        });

    }, [])

    return <PageBase>
        <PageHeader ref={searchbar} />
        <PageBody>
            <SectionHeader title={`Tags`} desc={"All of your available tags are listed below"} />
            {loading && <PageLoader label="Loading Tags"/>}
            {!loading && _.isEmpty(tags) && <EmptyState title="No Tags" message="Contact tags will be listed here once assigned" />}
            {!loading && !_.isEmpty(tags) && <div>
                <main className="mb-48">
                    <div className="mt-8 flow-root">
                        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <TagsList>
                                    {tags.map(tag => <Tag className="mb-2">{TagsHelper.getTagAsLabel(tag)}</Tag>)}
                                </TagsList>
                            </div>
                        </div>
                    </div>
                </main>
            </div>}
            {showContactModal && <ContactPage />}
        </PageBody>
    </PageBase>
}