import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectContact } from '../../store/slices/ui.slice';
import { API, ContactHelper } from '../../utils/api';

export const Name = ({ closeCmdBox, setLoadingMode }) => {

    const { ui: { selectedContact }, user: { token } } = useSelector(state => state);
    const dispatch = useDispatch();
    const [error, setError] = useState(null);

    const titleInputRef = useRef();
    const nameInputRef = useRef();

    const [name, setName] = useState();
    const [title, setTitle] = useState();

    useEffect(() => {
        setName(ContactHelper.getPrimaryName(selectedContact));
        setTitle(ContactHelper.getTitle(selectedContact));
    }, [selectedContact])

    useEffect(() => {
        if(titleInputRef) {
            titleInputRef.current.focus();
            titleInputRef.current.select();
        }
    }, [titleInputRef])

    const saveNewName = () => {
        setError(null);
        setLoadingMode(true);

        let updateObject = {};

        if(titleInputRef.current.value !== ContactHelper.getTitle(selectedContact)) updateObject['title'] = titleInputRef.current.value;
        if(nameInputRef.current.value !== ContactHelper.getPrimaryName(selectedContact)) updateObject['name'] = nameInputRef.current.value;

        API.updateContact(token, selectedContact.id, updateObject).then(contact => {
            dispatch(selectContact(contact));
            setLoadingMode(false);
            closeCmdBox();
        }).catch(error => {
            console.error("Error", error)
            setLoadingMode(false);
        })
    }

    const onKeydown = event => {
        event.stopPropagation();
        if(event.key === "Enter" && event.metaKey) saveNewName();
    }

    const onNameKeyDown = event => {
        event.stopPropagation();

        if(event.key === "Enter") nameInputRef.current.focus();
        if(event.key === "Enter" && event.metaKey) saveNewName();
    }

    const onFocus = event => {
        event.target.select();
    }

    return <>
        <header className="p-4 relative">
            <span className="font-sans font-semibold text-gray-900">Edit Details</span>
            {error && <span className="absolute right-4 top-4 text-xs leading-7 capitalize text-red-700">{error}</span>}
        </header>
        <div className="relative flex items-center">
            <div role="list" className="space-y-4 w-full p-4 pt-4">
            <div className="isolate -space-y-px rounded-md shadow-sm">
                <div className="relative rounded-md rounded-b-none px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:outline-none">
                    <label htmlFor="title" className="block text-xs font-medium text-gray-900">Name</label>
                    <input ref={nameInputRef} type="text" onFocus={onFocus} onKeyDown={onNameKeyDown} tabIndex={1} name="title" id="title" className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 focus:outline-none selection:bg-brand" placeholder="What's the contact new name?" value={name} onChange={event => setName(event.target.value)} />
                </div>
                <div className="relative rounded-md rounded-t-none px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:outline-none">
                    <label htmlFor="new-name" className="block text-xs font-medium text-gray-900 ">Title</label>
                    <input ref={titleInputRef} tabIndex={2} onFocus={onFocus} onKeyDown={onKeydown} type="text" name="new-name" id="new-name" className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 focus:outline-none selection:bg-brand" placeholder={`What describes ${name} best?`} value={title} onChange={event => setTitle(event.target.value)} />
                </div>
            </div>
            <div className="mt-2 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button tabIndex={3} type="button" className="select-none mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" onClick={saveNewName}>Save</button>
            </div>
            </div>
        </div>
    </>
}