import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import classNames from "classnames"
import _ from "lodash"
import { useState, useMemo, forwardRef, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { ImportAccount, ImportOption } from "../components/import.component"
import { Shortcut } from "../components/shortcut.component"
import { disconnect } from "../store/slices/user.slice"
import { API, API_URL, NotificationHelper, TagsHelper } from "../utils/api"
import { VIEWS } from "./main.page"
import { Searchbar, SearchResults } from "./sections/search.section"
import { setNotification, startLoading, stopLoading } from "../store/slices/ui.slice"
import { LinearProgress } from "@mui/material"

const navigation = [
    { name: 'Contacts', href: '/' },
    { name: 'Tags', href: '/tags'},
    { name: 'Classify', href: '/classify'}
  ]

const PUBLIC_PATH = process.env.PUBLIC_URL;

let updateInterval = null;

export const PageHeader = forwardRef(({selected, onImportReady}, ref) => {

    const { user: { token }, ui: { loading } } = useSelector(state => state);

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const dispatch = useDispatch();
    const [searchResults, setSearchResults] = useState([]);
    const [showImportOptions, setShowImportOptions] = useState(false);
    const [imports, setImports] = useState(null);
    const [selectedImportId, setSelectedImportId] = useState(null);

    const isTabCurrent = href => {
        let { pathname } = location;
        return pathname === href;
    }

    const loadImports = () => {
      
        API.getImports(token).then((imports) => {
  
          if(_.isEmpty(imports)) return;
          let pendingUpdate = false;

          _.forEach(imports, importDetails => {
            if(importDetails.state < 4) pendingUpdate = true;
          });

          if(pendingUpdate && updateInterval === null) {
            dispatch(startLoading());
            updateInterval = setInterval(loadImports, 2000);
          }

          if(!pendingUpdate && updateInterval) {
            clearInterval(updateInterval);
            dispatch(stopLoading());
            if(onImportReady) onImportReady();
          }
          
          setImports(imports);
          setSelectedImportId(_.first(imports).id)

        }).catch(error => {
          console.log(error);
          dispatch(setNotification(NotificationHelper.buildNotification("Loading Failed", "Couldn't load your imports, please reload.", NotificationHelper.ERROR)))
        });
    }

    const onImportGoogle = async () => {
        try {
          let { url } = await API.getAuthUrl();
          let childWindow = window.open(url);
  
          window.addEventListener('message', async event => {
              if(event.origin === API_URL || (process.env.NODE_ENV === "development" && ["http://127.0.0.1:8080", "http://localhost:8080"].indexOf(event.origin) >= 0)) {
                  const { authInfo, userInfo, source } = event.data;
                  let response = await API.addImport(token, authInfo, userInfo, source);
                  if(response === "ok") loadImports();
              }
              
          }, false);
  
        } catch(error) {}
    }

    const signOut = (event) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch(disconnect());
    }

    useEffect(loadImports, []);
    

    const importData = useMemo(() => {
        // if(onImportSelected) onImportSelected(selectedImportId);
        let d = _.first(_.filter(imports, {id: selectedImportId}));
        return d ? d : {};
    }, [selectedImportId]);

    return <>
    <div className="border-b border-gray-200 bg-white relative">
        {loading && <div className="absolute right-0 left-0 -bottom-3 h-4">
          <LinearProgress color="secondary" />
        </div>}
    <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8 ">
    <div className="relative flex h-16 items-center justify-between">
        
        <div className="flex">
                <div className="flex flex-shrink-0 items-center cursor-pointer" onClick={() => navigate('/')}>
                    <img className="block h-5 w-auto lg:hidden" src={`${PUBLIC_PATH}/images/contaclidate@3x.png`} alt="Contaclidate" />
                    <img className="hidden h-5 w-auto lg:block" src={`${PUBLIC_PATH}/images/contaclidate@3x.png`} alt="Contaclidate" />
                </div>
                <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                    {navigation.map((item) => (
                        <Link to={item.href} key={item.name} className={classNames( isTabCurrent(item.href) ? 'border-brand text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium' )} aria-current={item.current ? 'page' : undefined}>{item.name}</Link>
                    ))}
                    {selected && selected === VIEWS.TAGVIEW && (params && params.tag) && <Link to={`/tag/${params.tag}`} className={'border-indigo-500 text-gray-900 inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium'}>#{TagsHelper.getTagAsLabel(params.tag)}</Link>}
                </div>
        </div>
        <div className="flex flex-1 justify-center px-2 lg:ml-6 lg:justify-end">
            <div className="w-full max-w-lg lg:max-w-xs relative">
            <label htmlFor="search" className="sr-only">Search</label>
            <div className="group relative text-gray-400 focus-within:text-gray-600">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                <Searchbar onResults={setSearchResults} ref={ref} />
                <Shortcut shortcuts={["/"]} className="absolute right-0 right-2 top-1/2 -translate-y-1/2 group-focus-within:hidden" />

            </div>
            <SearchResults results={searchResults} onSelected={() => setSearchResults([])}/>
            </div>
        </div>
        <div className="hidden sm:ml-2 sm:flex sm:items-center flex-0 min-w-[20%]">
                    {/* Profile dropdown */}
                    <div className='w-full relative' onClick={() => setShowImportOptions(!showImportOptions)}>
                      <ImportAccount details={importData.details} progress={importData.progress} source={importData.source} />
                      {showImportOptions && <div className='absolute border border-color-stone-200 bg-white shadow rounded mt-1 left-0 right-0 inest-100 pt-1 z-30'>
                        <h3 className="px-2 text-xs font-medium leading-6 text-gray-700">Imported Accounts</h3>
                        {imports && <ul className=''>
                          {_.map(imports, (imprt, index) => {
                              let { details, source, state, progress } = imprt;
                              return <ImportOption key={`im.${index}`} state={state} details={details} source={source} progress={progress} onClick={() => { setShowImportOptions(false); }}/>
                          })}
                          <li className='border-t border-gray-200 mt-2'>
                            <span onClick={onImportGoogle} className='py-2 block w-full px-2 text-xs font-normal leading-6 text-gray-700 cursor-pointer hover:bg-gray-50'>Import another account</span>
                          </li>
                          <li className=''>
                            <span onClick={signOut} className='py-2 block w-full px-2 text-xs font-normal leading-6 text-gray-700 cursor-pointer hover:bg-gray-50'>Sign Out</span>
                          </li>
                        </ul>}
                      </div>}
                    </div>
                <div>
            </div>
        </div>
        </div>
        </div>
        </div>
    </>
})