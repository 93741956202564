export const EmptyState = ({title = "Empty", message = "No Content Available", cta, onClick}) => {
    
    return <div className="text-center pt-40">
                <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                </svg>
                <h3 className="mt-2 text-sm font-semibold text-gray-900">{title}</h3>
                <p className="mt-1 text-sm text-gray-500">{message}</p>
                <div className="mt-6">
                    {cta && onClick && <button onClick={onClick} type="button" className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">{cta}</button>}
                </div>
            </div>
};