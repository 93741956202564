import { useEffect, useRef } from 'react';
import { API } from '../utils/api';
import { useDispatch } from 'react-redux';
import { connect, setToken } from '../store/slices/user.slice';
import classes from '../scss/connect.module.scss';
import _ from 'lodash';

const image = ['connect-1', 'connect-2'][_.random(0, 1)];

export const ConnectPage = () => {

    let signinButtonRef = useRef(null);
    const dispatch = useDispatch();

    const startGoogle = (button) => {

        window.google.accounts.id.initialize({
            client_id: "63204555839-j2avpd48hkfktivdbaj6lanbcten3503.apps.googleusercontent.com",
            callback: (response) => {
                API.signInUser(response.credential).then(jwt => {
                    if(jwt) {
                        dispatch(setToken(jwt))
                        dispatch(connect())
                    }
                })
            }
        });
    
        window.google.accounts.id.renderButton(button,{ theme: "filled_blue", size: "large", text: 'continue_with' });
        window.google.accounts.id.prompt(); // also display the One Tap dialog
    }

    useEffect(() => {
        if(!signinButtonRef) return;
        let { current } = signinButtonRef;

        if(window.google) return startGoogle(current);
        window.onGoogleLibraryLoad = () => startGoogle(current)

    }, [signinButtonRef])

    const importGoogleContact = async () => {
       
    }

    return <div>
        <img src={`/images/${image}.webp`} className="h-screen absolute  w-full object-cover z-0 select-none" />
        <div className="bg-gradient-to-br from-slate-950 to-slate-600 h-screen absolute top-0 right-0 left-0  w-full opacity-80 select-none" />
        <main className="text-center pt-[30vh] relative z-10 select-none">
            <div className="flex justify-center">
                <figure className="rounded-md overflow-hidden w-16 h-16 border-b border-gray-400/20 shadow-xl shadow-white/40 mb-8 pointer-events-none select-none">
                        <img src={'/logo192x192.png'} className="" />
                </figure>
                
            </div>
            {/* <sup className="font-normal text-lg font-sans text-brand subpixel-antialiased">Contaclidate</sup> */}
            <h1 className="text-7xl font-sans font-bold leading-1 text-white drop-shadow-2xl subpixel-antialiased">Get Started</h1>
            <p className="text-2xl leading-7 mt-8 text-gray-200">Unify your contacts and start manage them as they deserve</p>
            <p  className='text-gray-300 mt-2'>Start for Free, No Credit Card Required</p>

            <div className="w-full mt-12 flex items-center justify-center ">
                <div ref={signinButtonRef}></div>
            </div>
        </main>
        <footer className='bg-white absolute top-[100vh] w-full shadow-inner'>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-6xl py-16 flex items-center flex-col">
                    <nav className="space-x-6 mb-6">
                        <a className="text-sm text-gray-500 hover:text-gray-700" href="https://contaclidate.com/about?utm_soruce=app&utm_medium=login" target={"_blank"}>About</a>
                        <a className="text-sm text-gray-500 hover:text-gray-700" href="https://contaclidate.com/privacy?utm_soruce=app&utm_medium=login" target={"_blank"}>Privacy</a>
                        <a className="text-sm text-gray-500 hover:text-gray-700" href="https://contaclidate.com/terms?utm_soruce=app&utm_medium=login" target={"_blank"}>Terms of Use</a>
                    </nav>
                    <p className="text-xs font-sans text-gray-500">© {(new Date()).getFullYear()} <a href="https://idan.yalovich.com?utm_source=app&utm_medium=login" target='_blank'>Idan Yalovich</a>, Inc. All rights reserved.</p>
                </div>
            </div>
        </footer>
    </div>

    return <div className={classes.connect}>
        <main>
            <section>
                <h1><span>Master</span>Your Contacts</h1>
                <p>Collect all email addresses and contacts you have connected-with into a single repository.</p>
                <div>
                    <div ref={signinButtonRef}></div>
                </div>
                <section>
                    <nav>
                        <a href="">How it Works</a>
                        <a href="">Privacy</a>
                        <a href="">Pricing</a>
                    </nav>
                </section>
            </section>
        </main>
        <aside>
            <img src={`/images/${image}.webp`} />
            <span />
        </aside>
    </div>;
}