import _ from 'lodash';

export const SectionHeader = ({title, desc, buttons}) => {

    return <header className="sm:flex items-center sm:justify-between">
        <div className="mx-auto max-w-7xl sm:w-0 sm:flex-1">
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">{title}</h1>
            <p className="mt-1 truncate text-sm text-gray-500">{desc}</p>
        </div>

        {buttons && <div className="mt-4 flex items-center justify-between sm:ml-6 sm:mt-0 sm:flex-shrink-0 sm:justify-start gap-x-1.5">
            {_.map(buttons, (button, index) => {
                return <button key={`bt.${index}`} onClick={button.onClick} type="button" className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    {button.icon}
                    {button.label}
                </button>
            })}
        </div>}
    </header>
}