import classNames from 'classnames';
import { useMemo, useRef, useState } from 'react';
import classes from '../../scss/commandBox.module.scss';
import { OptionBox } from '../commandBox.component';
import { Input } from '../input.component';
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { addBrowserFilter, clearBrowserCategory, clearBrowserFilters, setBrowserCategory } from '../../store/slices/ui.slice';
import { StrHelper } from '../../utils/api';

export const Query = ({ closeCmdBox, options }) => {

    const { ui: { browsing } } = useSelector(state => state);
    const optionsRef = useRef();
    const inputRef = useRef();
    const [optionsFilter, setOptionsFilter] = useState(null);
    const dispatch = useDispatch();

    const filterOptions = useMemo(() => {

        if(!_.isEmpty(options)) return _.map(options, option => {
           return `${StrHelper.ucFirst(option.name)} (${option.value})`
        }).sort();

        let deaultOptions = ["Tags", "Domains"];
        let output = _.filter(deaultOptions, option => option.toLowerCase() !== browsing.category);

        return output;

    }, [browsing.category, options])

    const onSubmit = filter => {
        filter = optionsRef.current.getHighlighted() ?? filter;
        setOptionsFilter(null);
        addFilter(filter);
    }

    const onSelect = filter => {
        setOptionsFilter(null);
        addFilter(filter);
    }

    const addFilter = filter => {

        if(browsing.category) {
            let tag = _.first(_.split(filter, /\s\(\d+\)/)).toLowerCase();
            console.log("---> ", tag)
            dispatch(addBrowserFilter(tag))
            closeCmdBox();
        } else {
            dispatch(setBrowserCategory(filter));
        }

        inputRef.current.clear();
    }

    const removeFilter = filter => {
        dispatch(clearBrowserCategory());
    }

    const onArrowUp = () => optionsRef.current.previous();
    const onArrowDown = () => optionsRef.current.next();

    return <>
        <header>
            <span>Manage Browsing Filters</span>
        </header>
        <div>
            <div className={classes.input}>
                <Input ref={inputRef} onSubmit={onSubmit} onChange={setOptionsFilter} onArrowUp={onArrowUp} onArrowDown={onArrowDown}/>
                <ul className={classes.existFilters}>
                    {_.isEmpty(browsing.category) && <li><i>No filters yet</i></li>}
                    {!_.isEmpty(browsing.category) && <li>
                        <sup>CATEGORY</sup>
                        <span>{browsing.category}</span>&nbsp;
                        <button onClick={() => {
                            dispatch(clearBrowserCategory());
                            dispatch(clearBrowserFilters());
                        }}>Remove</button>
                    </li>}
                    {!_.isEmpty(browsing.filters) && <li>
                        {_.map(browsing.filters, filter => {
                            return <li>
                                <sup>FILTER</sup>
                                <span>{filter}</span>&nbsp;
                                <button onClick={() => dispatch(clearBrowserFilters())}>Remove</button>
                            </li>
                        })}    
                    </li>}
                </ul>
            </div>
            <div className={classNames([classes.body])}>
                <OptionBox ref={optionsRef} options={filterOptions} onSelect={onSelect} filterKey={optionsFilter} />
            </div>
        </div>
    </>
}