import classes from '../../scss/contact.module.scss';
import { API, ContactHelper, TagsHelper } from '../../utils/api';
import { NewTag, Tag, TagsList } from '../../components/tag.component';
import { useEffect, useMemo, useRef, Fragment } from 'react';
import { SelectAll } from '../../components/input.component';
import _ from 'lodash';
import { ContactNotes } from '../../components/contact.component';
import { useDispatch } from 'react-redux';
import { selectContact, setShowContactModal } from '../../store/slices/ui.slice';
import { Transition } from '@headlessui/react';
import { XMarkIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { CheckBadgeIcon, TrashIcon, UsersIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import { ContactDetails } from './contact/contactDetails.section';
// import { EnvelopeIcon } from '@heroicons/react/20/solid';

const ContactInfoDataLabel = ({ label, htmlFor }) => {
    return <label htmlFor={htmlFor}>{label}</label>    
}

export const TABS = {
    DETAILS: 1,
    NOTES: 2
}

export const ContactSection = ({ contact, onCloseClicked, onEditClick, onDeleteClick, onMergeClick, onNewEmailClick, onNewPhoneClick, onRestoreClick, onVerifiedClick, onManageTagsClick, setActiveTab, activeTab }) => {

    const nameElm = useRef(null);
    const titleElm = useRef(null);
    const backgroundElm = useRef(null);
    const dispatch = useDispatch();

    const EDITING_ITEMS = {
        Name: 1,
        Title: 2
    }

    const otherEmails = useMemo(() => {
        return ContactHelper.getNonPrimaryEmailAddresses(contact);
    }, [contact])

    const otherNumbers = useMemo(() => {
        return ContactHelper.getNonPrimaryPhoneNumbers(contact);
    }, [contact])

    const onElmChange = (key, value) => {}

    const markAsVerified = () => {
        onVerifiedClick().then(contact => {

            dispatch(selectContact(contact));
        })
    }

    const onBackgroundClick = event => {
        if(backgroundElm.current === event.target) onCloseClicked();
    }

    const onCreateNoteClick = () => {
        setActiveTab(TABS.NOTES)
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }

    return <>
        <Transition.Root show={true} as={Fragment}>
            <div className="relative z-10" onClose={onCloseClicked} open={true}>
                <div className="fixed inset-0" />
                <div className="fixed inset-0 overflow-hidden bg-slate-800/60" ref={backgroundElm} onClick={onBackgroundClick}>
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                    <Transition.Child as={Fragment}
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                    >
                        <div className="pointer-events-auto w-screen max-w-3xl">
                        <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                            <div className="px-4 sm:px-6 pt-6 pb-6 bg-slate-800 sticky top-0 z-20 shadow-sm">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <span className="relative inline-block mr-4 min-w-10 shrink-0">
                                            <img className="h-10 w-10 rounded-full" src={contact.photo} alt="" />
                                            {contact.moderated && <span className="absolute bottom-0 left-0 block h-2.5 w-2.5 rounded-full bg-green-400 ring-2 ring-white" />}
                                        </span>
                                        <div className="space-y-1">
                                            <span className="flex gap-x-2 items-center">
                                                <h1 className="inline-flex text-base font-semibold leading-6 text-white" ref={nameElm}>{ContactHelper.getPrimaryName(contact)}</h1>
                                            </span>
                                            
                                            <span className="flex gap-x-2 items-center">
                                                <h2 className="text-sm text-gray-300" ref={titleElm} >{ContactHelper.getTitle(contact)}</h2>
                                            </span>
                                        </div>
                                    </div>


                                { /* Panel Controls */ }
                                    <div className="ml-3 flex h-7 items-center gap-x-3">
                                        <button type="button" className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" onClick={onEditClick}>
                                            Edit Details <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">E</span>
                                        </button>

                                        <button type="button" className="relative rounded text-gray-200 hover:text-white focus:outline-none p-1" onClick={onCloseClicked}>
                                            <span className="absolute -inset-2.5" />
                                            <span className="sr-only">Close panel</span>
                                            <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* Content */}
                            <div className="relative mt-4 flex-1 px-4 sm:px-6">
                                {/* Tabs */}
                                <div className="border-b border-gray-200 mb-6 relative">
                                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                        {[{name: "Details", href: TABS.DETAILS, current: activeTab === TABS.DETAILS}, {name: "Private Notes", href: TABS.NOTES, current: activeTab === TABS.NOTES}].map((tab) => (
                                            <a key={tab.name} href={tab.href} onClick={event => {
                                                event.stopPropagation(); event.preventDefault();
                                                setActiveTab(tab.href)
                                            }} className={classNames(tab.current ? 'border-brand text-brand' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'whitespace-nowrap border-b-2 py-4 px-2 text-sm font-medium' )} aria-current={tab.current ? 'page' : undefined}>
                                                {tab.name}
                                            </a>
                                        ))}
                                    </nav>

                                    {contact && contact.source && contact.source.label && contact.source.email &&  <p className='text-xs text-gray-500 absolute right-0 top-0 h-full flex items-center'>
                                        <span><strong>Source Account</strong><br />{contact.source.label} &lt;{contact.source.email}&gt; </span>
                                    </p>}
                                </div>

                                {activeTab === TABS.DETAILS && <ContactDetails contact={contact} onNewEmailClick={onNewEmailClick} onNewPhoneClick={onNewPhoneClick} onMergeClick={onMergeClick} onRestoreClick={onRestoreClick} onCreateNoteClick={onCreateNoteClick} />}
                                {activeTab === TABS.NOTES && <ContactNotes contact={contact} />}

                            </div>

                            {/* Footer Section */}
                            <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                {!contact.deleted && <button type="submit" className="justify-self-start rounded-md bg-white px-3 py-2 inline-flex items-center gap-x-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400" onClick={() => onDeleteClick()}>
                                    Delete <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">D</span>
                                </button>}
                                <button type="submit" className="ml-4 rounded-md bg-white px-3 py-2 inline-flex items-center gap-x-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400" onClick={() => onManageTagsClick()}>
                                    Manage Tags <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">T</span>
                                </button>
                                {!contact.deleted && <button type="submit" className="ml-4 rounded-md bg-white px-3 py-2 inline-flex items-center gap-x-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400" onClick={() => onMergeClick()}>
                                    Merge Contact <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">M</span>
                                </button>}
                                {!contact.moderated && !contact.deleted && <button type="button" className="ml-4 inline-flex items-center gap-x-1.5 rounded-md bg-brand hover:bg-brand-hover px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm" onClick={markAsVerified}>
                                    Mark as Verified <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">⌘⏎</span>
                                </button>}
                            </div>
                        </div>
                        </div>
                    </Transition.Child>
                    </div>
                </div>
            </div>
      </Transition.Root>
    </>
};