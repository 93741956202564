import { useEffect, useRef, forwardRef } from "react";
import { Shortcut } from "../shortcut.component";


export const Markdown = forwardRef(({ className, onSave, onClose, text, placeholder }, input) => {

    // const input = useRef(null);

    useEffect(() => {
        input.current.focus();
    }, [input.current])

    const onInput = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    const onKeyDown = (event) => {
        event.stopPropagation();

        let { code, metaKey, nativeEvent: {target: { innerText } } } = event;
        if(code === 'Enter' && metaKey && onSave && typeof(onSave) === "function") onSave(input.current.value)
        if(code === 'Escape' && onClose && typeof(onClose) === "function") onClose();
    }

    return <textarea placeholder={placeholder} ref={input} onInput={onInput} onKeyDown={onKeyDown} onKeyUp={event => event.stopPropagation()} rows={2} name="comment" id="comment" className="pl-3 py-2 pr-2 block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 focus:outline-none" defaultValue={text}>{text}</textarea>
})