import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { API, ContactHelper, NavigationHelper } from "../../utils/api";
import { Input } from "../input.component";
import _ from 'lodash';
import { selectContact } from '../../store/slices/ui.slice';
import classes from '../../scss/commandBox.module.scss';
import { OptionBox } from "../commandBox.component";
import { UsersIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

const MERGE_STATES = {
    SelectContact: 0,
    ConfirmMerge: 1
}

export const Merge = ({ closeCmdBox, setLoadingMode }) => {

    const { ui: { selectedContact }, user: { token } } = useSelector(state => state);

    const [results, setResults] = useState([]);
    const [mergeTo, setMergeTo] = useState(null);
    const [mergeState, setMergeState] = useState(0);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const optionsRef = useRef();

    useEffect(() => {
        let autoSearchName = ContactHelper.getPrimaryName(selectedContact);
        search(autoSearchName);
    }, [selectedContact])
        
    const search = (query) => {

        if(query.length === 0) {
            setResults([]); return;
        }

        setLoadingMode(true);
        API.searchByName(token, query).then(response => {
            setResults(_.filter(response, (result => (result.id !== selectedContact.id))));
            setLoadingMode(false);
        }).catch(error => {

        })
    }

    const setSearch = useRef(_.debounce(search, 350));
    const onChange = query => setSearch.current(query);

    const options = useMemo(() => {
       return _.map(results, result => {
            return `${ContactHelper.getPrimaryName(result)} ${ContactHelper.isEmailExist(result) ? `<${ContactHelper.getPrimaryEmailAddress(result)}>` : ``}`;
        })
    }, [results]);

    const mergeOptions = useMemo(() => {
        if(!mergeTo) return [];

        return [
            `Merge ${ContactHelper.getPrimaryName(selectedContact)} ${ContactHelper.isEmailExist(selectedContact) ? `<${ContactHelper.getPrimaryEmailAddress(selectedContact)}>` : ``} to ${ContactHelper.getPrimaryName(mergeTo)} ${ContactHelper.isEmailExist(mergeTo) ? `<${ContactHelper.getPrimaryEmailAddress(mergeTo)}>` : ``}`,
            `Merge ${ContactHelper.getPrimaryName(mergeTo)} ${ContactHelper.isEmailExist(mergeTo) ? `<${ContactHelper.getPrimaryEmailAddress(mergeTo)}>` : ``} to ${ContactHelper.getPrimaryName(selectedContact)} ${ContactHelper.isEmailExist(selectedContact) ? `<${ContactHelper.getPrimaryEmailAddress(selectedContact)}>` : ``}`
        ]
    }, [mergeTo]);

    const onArrowUp = () => optionsRef.current.previous();
    const onArrowDown = () => optionsRef.current.next();

    const onSubmit = (name) => {

        name = optionsRef.current.getHighlighted() ?? null;

        if(mergeState === MERGE_STATES.SelectContact) return confirmMerge(name, optionsRef.current.getHighlightIndex());
        if(mergeState === MERGE_STATES.ConfirmMerge) return mergeConfirmed(name, optionsRef.current.getHighlightIndex());
    }

    const onSelect = (select, selectionIndex) => {
        if(mergeState === MERGE_STATES.SelectContact) return confirmMerge(select, selectionIndex);
        if(mergeState === MERGE_STATES.ConfirmMerge) return mergeConfirmed(select);
    }

    const confirmMerge = (name, selectionIndex) => {

        if(!results[selectionIndex]) {
            console.warn("Failed to find contact to merge, aborting");
            return 
        }

        setMergeTo(results[selectionIndex]);
        setMergeState(MERGE_STATES.ConfirmMerge);
    }

    const mergeConfirmed = direction => {
        let index = _.findIndex(mergeOptions, option => option === direction);
        if(index === 0) return onMerge(selectedContact, mergeTo);
        if(index === 1) return onMerge(mergeTo, selectedContact);
    }

    const onMerge = (source, target) => {

        setLoadingMode(true);

        API.mergeContact(token, source, target).then(response => {
            let { id } = response;
            dispatch(selectContact(response));
            setLoadingMode(false);
            closeCmdBox();
            if(NavigationHelper.isUrlIncludeContactId(location.pathname)) navigate(`/contact/${id}`, { replace: true });

        }).catch(error => {

        })
    }

    return <>
        <header className="p-4 relative">
            <span className="font-sans font-semibold text-gray-900">Merge Contacts</span>
            <div className="absolute right-4 top-3 select-none">
                <ol role="list" className="flex rounded-md bg-white px-4 border border-gray-200 h-8">
                    <li className="flex cursor-pointer" onClick={() => setMergeState(MERGE_STATES.SelectContact)}>
                        <div className="flex items-center">
                            <span className={classNames(["mr-2 text-xs font-medium", (mergeState === MERGE_STATES.ConfirmMerge ? "text-gray-700" : "text-gray-500")])}>Step 1: Pick Contact</span>
                        </div>
                    </li>
                    <li className="flex ml-0">
                        <div className="flex items-center">
                        <svg className="h-full w-4 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" aria-hidden="true">
                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <span className="ml-4 text-xs font-medium text-gray-500">Step 2: Define Merge</span>
                        </div>
                    </li>
                </ol>
            </div>
        </header>
        <div className="relative flex items-center">
            <UsersIcon className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
            <Input placeholder={mergeState === MERGE_STATES.SelectContact ? "Search Contact" : "Select How to Merge"} onSubmit={onSubmit} onChange={onChange} onArrowUp={onArrowUp} onArrowDown={onArrowDown}/>
        </div>
        
        
        <div>
            
            <div className={classes.body}>
                <OptionBox ref={optionsRef} options={mergeState === MERGE_STATES.SelectContact ? options : mergeOptions} onSelect={onSelect} />
                {/* <OptionBox ref={optionsRef} options={results} filterKey={filter} onSelect={addNewTag}/> */}
            </div>
    </div>
    </>
}
