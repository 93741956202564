import React, { forwardRef, useImperativeHandle } from 'react';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import classes from '../scss/input.module.scss';

export const Input = forwardRef(({ label, placeholder, initialValue = "", onChange, onSubmit, onArrowUp, onArrowDown, noAutoClear, onBackspace, className, hint }, ref) => {

    const input = useRef(null);
    const [value, setValue] = useState(initialValue);

    useImperativeHandle(ref, () => ({
        clear: () => setValue(""),
        setValue,
        getValue: () => value,
        focus: () => input.current.focus()
    }))

    const onKeyUp = event => {

        if(event.code === "Escape") return;

        event.stopPropagation();
        event.preventDefault();

        if(event.code === "Enter") {
            if(typeof(onSubmit) === "function") onSubmit(value)
            if(!noAutoClear) setValue('');
            return ;
        }

        if(event.code === "ArrowDown") {
            if(typeof(onArrowDown) === "function") onArrowDown();
            return;
        }

        if(event.code === "ArrowUp") {
            if(typeof(onArrowUp) === "function") onArrowUp();
            return;
        }

        if(event.code === "Backspace" && value.length === 0) {
            if(typeof(onBackspace) === "function") onBackspace();
        }
    }

    const onValueChange = event => {
        setValue(event.target.value);
        if(typeof(onChange) === "function") onChange(event.target.value);
    }

    useEffect(() => {
        if(input) input.current.focus();
    }, [input])


    return <input className='h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 focus:outline-none sm:text-sm' ref={input} id={`input.${label}`} value={value} onChange={onValueChange} placeholder={placeholder} onKeyUp={onKeyUp} autoComplete={"off"} autoCorrect={"false"} />

    return <div className={classNames([classes.input, className])}>
        {label && <label htmlFor={`input.${label}`}>{label}</label>}
        <input ref={input} id={`input.${label}`} value={value} onChange={onValueChange} placeholder={placeholder} onKeyUp={onKeyUp} autoComplete={"off"} autoCorrect={"false"} />
        {hint && <i className={classes.hint}>{hint}</i>}
    </div>
});

export const ContentEditable = ({ children, content }) => {


}

export const SelectAll = (element) => {
    if(document.body.createTextRange) {
        let range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();    
        return;
    }

    if(window.getSelection) {
        let selection = window.getSelection();
        let range = document.createRange();
        range.selectNodeContents(element);
        selection.removeAllRanges();
        selection.addRange(range);
        return;
    }
}