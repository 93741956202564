import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API, ContactHelper, NotificationHelper } from '../../utils/api';
import { NotesEditor } from '../contact.component';
import { setNotification } from '../../store/slices/ui.slice';

export const Note = ({ closeCmdBox, setLoadingMode }) => {

    const { ui: { selectedContact }, user: { token } } = useSelector(state => state);
    const dispatch = useDispatch();
    const [error, setError] = useState(null);

    const saveNewNote = (note) => {
        setError(null);
        setLoadingMode(true);

        if(_.isEmpty(note)) {
            setError("Can't save an empty note");
            setLoadingMode(false);
            return;
        }

        API.saveNote(token, selectedContact.id, note).then(response => {
            dispatch(setNotification(NotificationHelper.buildNotification("Note Created", `Saved to ${ContactHelper.getPrimaryName(selectedContact)}'s profile`, NotificationHelper.SUCCESSS, true)));       
            if(response) closeCmdBox()
        }).catch(error => {
            setError("Failed to save note, please try again", error);
            setLoadingMode(false);
        });
    }

    return <>
        <header className="p-4 relative">
            <span className="font-sans font-semibold text-gray-900">Add Note</span>
            {error && <span className="absolute right-4 top-4 text-xs leading-7 capitalize text-red-700">{error}</span>}
        </header>
        <div className="relative flex items-center">
            <ul role="list" className="space-y-6 w-full p-4 pt-4">
                <NotesEditor onSave={saveNewNote} className="!mt-0" name={ContactHelper.getPrimaryName(selectedContact)} onClose={closeCmdBox} />
            </ul>
        </div>
    </>
}