import { createSlice } from '@reduxjs/toolkit'

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const shortcutsSlice = createSlice({
  name: 'shortcuts',
  initialState: {
    key: null,
    shift: false,
    alt: false,
    meta: false,
    code: null
  },
  reducers: {
    clearShortcut: (state) => {
        state.key = null;
        state.shift = false;
        state.alt = false;
        state.meta = false;
        state.code = null;
    },
    activateShortcut: (state, action) => {
        let { key, shiftKey, altKey, metaKey, code } = action.payload;


        if(!key || !code) return;

        key = key.toLowerCase();
        code = code.toLowerCase();

        if(isDev) console.log(`Shortcut clicked: KEY: ${key}, Shift: ${shiftKey}, Alt: ${altKey}, Meta: ${metaKey}, Code: ${code}`);

        state.key = key;
        state.shift = shiftKey ?? false;
        state.alt = altKey ?? false;
        state.meta = metaKey ?? false;
        state.code = code;
    }
  },
})

export const { clearShortcut, activateShortcut } = shortcutsSlice.actions;

export default shortcutsSlice.reducer;