import { createSlice } from '@reduxjs/toolkit'

const SESSION_KEY = 'cid';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    isConnected: localStorage.getItem(SESSION_KEY) !== null,
    profile: {},
    token: localStorage.getItem(SESSION_KEY)
  },
  reducers: {
    connect: (state) => {
        state.isConnected = true;
    },
    disconnect: state => {
        state.isConnected = false;
        localStorage.removeItem(SESSION_KEY);
    },
    updateProfile: (state, action) => {
        state.profile = action.payload;
    },
    setToken: (state, action) => {
        localStorage.setItem(SESSION_KEY, action.payload);
        state.token = action.payload;
    }
  },
})

export const { connect, disconnect, updateProfile, setToken } = userSlice.actions;

export default userSlice.reducer;