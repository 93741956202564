import { configureStore } from '@reduxjs/toolkit';
import shortcutsSlice from './slices/shortcuts.slice';
import userReducer from './slices/user.slice';
import uiReducer from './slices/ui.slice';

export default configureStore({
  reducer: {
      user: userReducer,
      shortcuts: shortcutsSlice,
      ui: uiReducer
  },
})