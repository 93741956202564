
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { pushPath } from '../store/slices/ui.slice';
import { Tooltip } from './tooltip.component';

export const TagsList = ({children, className, viewOnly}) => {
    return <ul className={classNames(["list-none", className])}>
        {children}
    </ul>
}

export const Tag = ({children, className, onRemoveClick}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onTagClick = event => {
        dispatch(pushPath(`/tag/${children.toLowerCase()}`));
        navigate(`/tag/${children.toLowerCase()}`);
    }

    return <li className={classNames(['inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600 mr-2 cursor-pointer focus:shadow-sm', className])} onClick={onTagClick}>
        #{children}
        {onRemoveClick && <button onClick={onRemoveClick} type="button" className="ml-1 group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20">
          <span className="sr-only">Remove</span><svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-gray-600/50 group-hover:stroke-gray-600/75"><path d="M4 4l6 6m0-6l-6 6" /></svg><span className="absolute -inset-1" />
        </button>}
    </li>
}

export const NewTag = ({onClick}) => {
    return <li onClick={onClick} className='inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600'><Tooltip title='Add new tag' shortcuts={['T']}><span>new tag</span></Tooltip></li>
}

