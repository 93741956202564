import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { Provider } from 'react-redux'
import store from './store'
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()]
})
BugsnagPerformance.start({ apiKey: process.env.REACT_APP_BUGSNAG_KEY })

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const theme = createTheme({
  palette: {
    primary: {
      main: '#0F1C2E'
    },
    secondary: {
      main: '#E0B768'
    }

  }
})

// Bugsnag.notify(new Error('Test error'))

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
