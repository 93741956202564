import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    selectedContact: null,
    highlightContact: null,
    contactsList: [],
    loading: false,
    showContactModal: false,
    showNoteEditor: false,
    tags: [],
    notification: {
      autoFade: false,
      show: false,
      type: 'none', // info, success, warn, error,
      title: null,
      message: null
    },
    browsing: {
      category: 'tags',
      filters: []
    },
    pathHistory: []
  },
  reducers: {
    selectContact: (state, action) => {
        state.selectedContact = action.payload;
    },
    clearContactSelection: (state) => {
        state.selectedContact = null;
    },
    highlightContact: (state, action) => {
      state.highlightContact = action.payload;
    },
    setContactsList: (state, action) => {
      state.contactsList = action.payload;
    },
    clearContactsList: (state) => {
      state.contactsList = [];
    },
    setBrowserCategory: (state, action) => {
      state.browsing.category = action.payload.toLowerCase();
    },
    clearBrowserCategory: (state) => {
      state.browsing.category = null;
    },
    addBrowserFilter: (state, action) => {
      state.browsing.filters = [action.payload]

      // let list = state.browsing.filters;
      // list.push(action.payload.toLowerCase())
      // state.browsing.filters = _.uniq(list);
    },
    clearBrowserFilters: (state, action) => {
      state.browsing.filters = [];
    },
    startLoading: state => {
      state.loading = true;
    },
    stopLoading: state => {
      state.loading = false;
    },
    setShowContactModal: (state, action) => {
      state.showContactModal = action.payload;
    },
    setShowNotesEditor: (state, action) => {
      state.showNoteEditor = action.payload
    },
    pushPath: (state, action) => {
      let history = state.pathHistory;
      history.unshift(action.payload)

      state.pathHistory = _.first(_.chunk(history, 2));
    },
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
    hideNotification: (state, action) => {
      state.notification.show = false;
      state.notification.autoFade = false;
    }
  },
})

export const { 
  selectContact, 
  clearContactSelection, 
  highlightContact, 
  setContactsList, 
  clearContactsList,
  setBrowserCategory,
  clearBrowserCategory,
  addBrowserFilter,
  clearBrowserFilters,
  startLoading,
  stopLoading,
  setShowContactModal,
  setShowNotesEditor,
  pushPath,
  setNotification,
  hideNotification
} = uiSlice.actions;

export default uiSlice.reducer;