
import { CircularProgress } from '@mui/material';
import Chip from '@mui/material/Chip';
import _ from 'lodash'
import { useEffect, useMemo, useRef, useState, useTransition } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ContactRow, ContactsList } from '../../components/contact.component';
import { Input } from '../../components/input.component';
import classes from '../../scss/sections.module.scss';
import { selectContact, startLoading, stopLoading } from '../../store/slices/ui.slice';
import { API, ContactHelper } from '../../utils/api';
// import formatDistance from 'date-fns/formatDistance'
import { SearchSuggestions } from '../../components/searchSuggestions';
import { Icon } from '../../components/icon.component';
import { forwardRef } from 'react';
import debounce from 'lodash.debounce';

class PseudoTerm {
    constructor(term) {
        console.log("--->", term);
    }
}

const EmptyRsults = () => {
    return <div className={classes.emptyResults}>
        <Icon name={'folder'} className={classes.emptyResultsIcon}/>
        <h5>No Results</h5>
        <p>Type different search term</p>
    </div>
}

export const Searchbar = forwardRef(({onResults}, searchbar) => {

    const { user: { token }, ui: { loading } } = useSelector(state => state);
    const [value, setValue] = useState('');

    const onChange = event => {
        setValue(event.target.value);
        debouncedSearch(event.target.value);
    }

    const search = (term, filtes = null) => {
        if(_.isEmpty(term)) return onResults([]);
        API.search(token, term, []).then(onResults).catch(error => {console.log("ERROR: ", error);})
    }

    const debouncedSearch = useMemo(() => { return debounce(search, 500); }, []);

    return <input id="search" ref={searchbar} onChange={onChange} value={value} className="block w-full rounded-md border bg-white py-1.5 pl-10 pr-3 outline-none text-gray-900 focus:ring-white focus:ring-offset-stone-600 sm:text-sm sm:leading-6" placeholder="Search" type="search" name="search" />;
})

export const SearchResults = ({results, onSelected}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onContactSelect = contact => {
        dispatch(selectContact(contact));
        navigate(`/contact/${contact.id}`);
        onSelected();
    }
    
    if(_.isEmpty(results)) return null;

    return <div className="absolute z-30 top-full left-0 right-0 bg-white drop-shadow-lg px-4">
        <ul role="list" className="divide-y divide-gray-100">
            {results.map(person => {
                return <li key={ContactHelper.getPrimaryEmailAddress(person)} className="flex items-center justify-between gap-x-4 py-4">
                    <div className="flex min-w-0 gap-x-4 items-center">
                    <img className="h-10 w-10 flex-none rounded-full bg-gray-50" src={person.photo} alt="" />
                    <div className="min-w-0 flex-auto">
                        <p className="text-sm font-semibold leading-4 text-gray-900">{ContactHelper.getPrimaryName(person)}</p>
                        <p className="mt-1 truncate text-xs leading-5 text-gray-500">{ContactHelper.getTitle(person)} | {ContactHelper.getPrimaryEmailAddress(person)}</p>
                    </div>
                    </div>
                    <button onClick={() => onContactSelect(person)} className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">View</button>
              </li>
            })}
        </ul>
        {/* {results.length} */}
    </div>
}

export const Search = () => {

    const { user: { token }, ui: { loading } } = useSelector(state => state);

    const inputRef = useRef();
    const searchSuggestionsRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [results, setResults] = useState(null);
    const [recentSearches, setRecentSearches] = useState([]);
    const [recetResults, setRecentResults] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [tags, setTags] = useState([]);
    const [query, setQuery] = useState({
        term: '',
        filters: []
    });

    const keys = [{
        suggest: "\\s?in:|^in:",
        split: "in:",
        phrase: ""
    }];

    const suggest = useRef(_.debounce(() => {
        console.log("Suggest content for");
    }, 350));

    const noResults = useMemo(() => {
        return (results !== null && _.isEmpty(results)) ? true : false;
    }, [results])

    useEffect(() => {

        API.getContactGroupedBy(token, 'tags').then(tags => {
            setTags(_.reverse(_.sortBy(tags, ['value'])));
        }).catch(error => {

        })

        API.getRecentSearches(token).then(setRecentSearches).catch(error => {
            console.log('failed to load recent searches.', error);
        })
        
    }, [])

    const search = (term, filtes = null) => {
        inputRef.current.setValue(term);
        dispatch(startLoading());

        let searchFilters = filtes ? filtes : query.filters;

        API.search(token, term, searchFilters).then(response => {
            setResults(response);
            dispatch(stopLoading());
        }).catch(error => {
            console.log("ERROR: ", error);
        })
    }

    const setInSuggestions = (query) => {
        let workingCopy = _.isEmpty(query) ? tags : _.filter(tags, tag => (new RegExp(query, 'i')).test(tag.name));
        setSuggestions(_.map(_.first(_.chunk(workingCopy, 5)), tag => `in:${tag.name}`));
    }

    const resetView = () => {
        setSuggestions([]);
        setResults(null);
    }

    const clearSearch = () => {
        setQuery({
            term: '',
            filters: []
        });
        resetView();
        inputRef.current.clear();
        inputRef.current.focus();
    }

    const onChange = async value => {

        if(_.isEmpty(value)) return resetView();

        let pesudoCheckResponse = await isMatchingPesudoFilter(value);
        if(_.first(pesudoCheckResponse)) deployFilterUpdates(_.last(pesudoCheckResponse));
    };

    const isMatchingPesudoFilter = (value, skipSpace = false) => {
        return new Promise(resolve => {
            _.forEach(keys, (key, index) => {
                if((new RegExp(key.suggest)).test(value)) {
                    let extractedPesudo = _.first(_.filter(_.split(value, key.split), i => !_.isEmpty(i)));

                    if(skipSpace) return resolve([true, {
                        filters: addPesudoFilter(_.trimEnd(extractedPesudo), 'tag', _.trimEnd(value)),
                        term: value.replace(value, '')
                    }])

                    if((new RegExp(`.*\\s`,'i').test(extractedPesudo))) 
                    {
                        return resolve([true, {
                            filters: addPesudoFilter(_.trimEnd(extractedPesudo), 'tag', _.trimEnd(value)),
                            term: value.replace(value, '')
                        }]);
                    }
    
                    if(index === 0) {
                        setInSuggestions(extractedPesudo);
                    }
                }

                if(index === (keys.length-1)) resolve([false]);
            });
        });
    }

    const deployFilterUpdates = updated => {
        let updates = _.clone(query);
        commitQueryUpdates(_.merge(updates, updated));
        suggest.current();
    }

    const commitQueryUpdates = (updates, clearInput = true) => {
        setQuery(updates);
        if(clearInput) inputRef.current.setValue(updates.term);
    }

    const addPesudoFilter = (filter, type = "tags", label) => {
        let current = _.clone(query.filters);
        current.push({ filter: filter.toLowerCase(), type, label: label.toLowerCase() });
        return _.uniqWith(current, _.isEqual);
    }

    const onSubmit = async value => {

        if(searchSuggestionsRef.current) {    
            let executed = searchSuggestionsRef.current.executeHighlightIfAvailable();
            if(executed) return;
        }

        resetView();

        let pesudoCheckResponse = await isMatchingPesudoFilter(value, true);
        if(_.first(pesudoCheckResponse)) return deployFilterUpdates(_.last(pesudoCheckResponse));

        search(value);
    }

    const removePesudoFilter = (filter) => {
        let current = _.clone(query);
        current.filters = _.filter(current.filters, ftr => !_.isEqual(ftr, filter));
        commitQueryUpdates(current, false);

        inputRef.current.focus();
    } 

    const applyRecentSearch = (searchObj) => {
        let current = _.clone(query);
        current.filters = searchObj.filters;
        current.term = searchObj.query;

        commitQueryUpdates(current, false);

        search(searchObj.query, searchObj.filters);
        inputRef.current.focus();
    }

    const applySuggestion = async (suggestion) => {
        resetView();

        let pesudoCheckResponse = await isMatchingPesudoFilter(suggestion, true);
        if(_.first(pesudoCheckResponse)) return deployFilterUpdates(_.last(pesudoCheckResponse));

        inputRef.current.focus();
    }

    const displayMeta = useMemo(() => {
        return _.isEmpty(results);
    }, [results])

    const onBackspace = () => {
        if(_.isEmpty(query.filters)) return;

        let filter = _.last(query.filters);
        removePesudoFilter(filter);
    }

    const onArrowUp = () => searchSuggestionsRef.current.previous();
    const onArrowDown = () => searchSuggestionsRef.current.next();

    return <div className={classes.search}>
        <header>
            <div>
                <ul>
                    {_.map(query.filters, (ftr, findex) => {
                        return <li key={findex}><Chip classes={{
                            label: classes.chipLabel
                        }} label={ftr.label} variant={'outlined'} onDelete={() => removePesudoFilter(ftr)}/></li>;
                    })}
                </ul>
                <div>
                    <Input ref={inputRef} noAutoClear placeholder="Search" initialValue={query.term} onChange={onChange} onSubmit={onSubmit} onArrowUp={onArrowUp} onArrowDown={onArrowDown} onBackspace={onBackspace} />
                </div>
            </div>
            
            {/* <IconButton onClick={() => navigate(-1)}>
                    
            </IconButton> */}
            <button onClick={() => clearSearch()}>
                X
            </button>
        </header>
        <main>
            {!noResults && displayMeta && <div className={classes.suggestions}>
                <SearchSuggestions ref={searchSuggestionsRef} recentSearches={recentSearches} searchSuggestions={suggestions} onRecentSelected={applyRecentSearch} onSuggestionSelected={applySuggestion}/>
            </div>}
            <div className={classes.results}>
                {results && <ContactsList>{_.map(results, (result, index) => <ContactRow contact={result} key={index} />)}</ContactsList>}
                {results && _.isEmpty(results) && <EmptyRsults />}

                {loading && <CircularProgress size={24} color="inherit" />}
            </div>
        </main>
    </div>
};