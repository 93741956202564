
// import classes from '../scss/app.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, Fragment, useRef } from 'react';
import { clearShortcut } from '../store/slices/shortcuts.slice';
import { API, API_URL, ContactHelper, NavigationHelper, TagsHelper } from '../utils/api';
import { pushPath, selectContact, setShowContactModal } from '../store/slices/ui.slice';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ContactPage } from './contact.page';
import _ from 'lodash';
import { Lean } from './sections/lean.section';
import { useMemo } from 'react';
import { PageHeader } from './header.page';
import { PageBody } from './body.page';
import { PageBase } from './base.page';
import { Notification } from '../components/notification.component';

export const VIEWS = {
  HOME: '/',
  CLASSIFY: '/classify',
  BRWOSE: '/browse',
  LIST: '/list',
  SEARCH: '/search',
  CONTACT: 'contact',
  TAGVIEW: '/tag/:tag',
  DOMAINVIEW: '/domain/:domain'
}

export const getDefaultView = () => VIEWS.HOME;

export const MainPage = () => {

    const { shortcuts: { key }, ui: { selectedContact, pathHistory, showContactModal, notification } } = useSelector(state => state);
    const dispatch = useDispatch();
    const location = useLocation();
    const searchbar = useRef(null);
    let params = useParams();

    const [updateContactsIndex, setUpdateContactsIndex] = useState(0);
    const [selected, setSelected] = useState(getDefaultView());

    const TAGS_URL_REGEX = new RegExp('^/tag/[a-z]?', 'i');
    const isUrlIncludeTags = () => {
      let { pathname } = location;
      return TAGS_URL_REGEX.test(pathname);
    }

    const DOMAINS_URL_REGEX = new RegExp('^/domain/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]', 'i');
    const isUrlIncludeDomains = () => {
      let { pathname } = location;
      return DOMAINS_URL_REGEX.test(pathname);
    }

    useEffect(() => {
      
      let { pathname } = location;
      dispatch(pushPath(pathname));

      if(NavigationHelper.isUrlIncludeContactId(pathname)) {
        return dispatch(setShowContactModal(true));
      }
      if(isUrlIncludeTags()) {
        dispatch(setShowContactModal(false));
        return setSelected(VIEWS.TAGVIEW);
      }

      if(isUrlIncludeDomains()) {
        dispatch(setShowContactModal(false));
        return setSelected(VIEWS.DOMAINVIEW);
      }
      
      if(pathname === '/') return setSelected(getDefaultView());

    }, [location])

    const tag = useMemo(() => {
      if(params.tag) return params.tag;

      if(NavigationHelper.isUrlIncludeContactId(location.pathname)) 
      {
        let lastPath = _.last(pathHistory);
        if((TAGS_URL_REGEX.test(lastPath))) {
          return _.last(_.split(lastPath, "/"));
        }
      }
      return null;

    }, [location, params])

    const domain = useMemo(() => {
      if(params.domain) return params.domain;

      if(NavigationHelper.isUrlIncludeContactId(location.pathname)) 
      {
        let lastPath = _.last(pathHistory);
        if((DOMAINS_URL_REGEX.test(lastPath))) {
          return _.last(_.split(lastPath, "/"));
        }
      }

      return null
    })


    useEffect(() => {

      if(key === '/') {
        searchbar.current.focus();
        dispatch(clearShortcut());
      }

    }, [key]);

    const onImportReady = () => {
      setUpdateContactsIndex((updateContactsIndex+1));
    }

     
    return  <>
    <PageBase>
        <PageHeader selected={selected} ref={searchbar} onImportReady={onImportReady} />
        <PageBody>
          {selected === VIEWS.HOME && <Lean triggerContactsUpdate={updateContactsIndex} />}
          {selected === VIEWS.TAGVIEW && <Lean tag={tag} />}
          {selected === VIEWS.DOMAINVIEW && <Lean domain={domain} />}
          {showContactModal && <ContactPage />}
          {notification.show && <Notification />}
        </PageBody>
    </PageBase>

 </>   

}