import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classes from '../../scss/commandBox.module.scss';
import { selectContact } from '../../store/slices/ui.slice';
import { API } from '../../utils/api';

export const Delete = ({ closeCmdBox }) => {

    const { ui: { selectedContact }, user: { token } } = useSelector(state => state);
    const dispatch = useDispatch();
    const confirmButton = useRef(null);

    const onDeleteConfirmed = () => {
        API.markAsDeleted(token, selectedContact.id).then(contact => {
            if(contact.deleted) {
              dispatch(selectContact(contact));
            }
            closeCmdBox();
        }).catch(error => {
            console.log("Failed!");
        }) 
    }

    useEffect(() => {
      confirmButton.current.focus();
    }, [confirmButton])

    return <div className="relative w-full transform overflow-hidden rounded-lg bg-white px-8 pl-6 text-left transition-all sm:my-8">
    <div className="sm:flex sm:items-start">
      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
        <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
      </div>
      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
        <h3 className="text-base font-semibold leading-6 text-gray-900 select-none">Delete Contact</h3>
        <div className="mt-2">
          <p className="text-sm text-gray-500 select-none">
            Are you sure you want to delete contact? You will be able to access the contact from a different interface. This action can be undone.
          </p>
        </div>
      </div>
    </div>
    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
      <button ref={confirmButton} type="button" className="select-none inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto" onClick={onDeleteConfirmed}>Delete </button>
      <button type="button" className="select-none mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" onClick={closeCmdBox}>Cancel</button>
    </div>
  </div>

    return <>
        <header>
            <span>Delete contact</span>
        </header>
        <div className={classes.largeMessage}>
            <div className={classNames([classes.body])}>
                Hit another <kbd>d</kbd> to confirm contact deletion
            </div>
        </div>
    </>
}