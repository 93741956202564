import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, useNavigate } from "react-router-dom";
import { ConnectPage } from './pages/connect.page';
import { MainPage } from './pages/main.page';
import { OnboardingPage } from './pages/onboarding.page';
import { activateShortcut, clearShortcut } from './store/slices/shortcuts.slice';
import { useEffect, useMemo, useState } from 'react';
import { API } from './utils/api';
import { ErrorPage } from './pages/error.page';
import './main.css';
import { updateProfile } from './store/slices/user.slice';
import { TagsPage } from './pages/tags.page';
import { ClassifyPage } from './pages/classify.page';
import { PageLoader } from './components/loader.component';
import { Analytics } from './utils/analytics';

Analytics.init();

const App = () => {

  const { user: { isConnected, token }, shortcuts: { key } } = useSelector(state => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(clearShortcut()); // Cleanup shortcut
  }, [key])

  useEffect(() => {

    if(loading) return;

    window.addEventListener('keydown', event => {
      let { key, metaKey, shiftKey, altKey, code } = event;  

      if(key && key.toLowerCase && ['tab', 'meta'].indexOf(key.toLowerCase()) >= 0) {
          event.preventDefault();
          event.stopPropagation();
          return;
      }

      if(metaKey) {
        dispatch(activateShortcut({ key, shiftKey, altKey, metaKey, code }))
      }

    }, false);


    window.addEventListener('keyup', event => {
      let { key, shiftKey, altKey, metaKey, code } = event;

      if(key && key.toLowerCase && ['shift', 'meta', 'alt'].indexOf(key.toLowerCase()) >= 0) return;

      dispatch(activateShortcut({ key, shiftKey, altKey, metaKey, code }));
      event.preventDefault();
      event.stopPropagation();
    }, false);

  }, [loading]);

  const start = async () => {
    await API.getUser(token).then(userInfo => {
      Analytics.setUserId(userInfo.id);
      dispatch(updateProfile(userInfo));
    })

    API.getImports(token).then(imports => {
      
      if(imports.length === 0) {  
        navigate('/get-started');
        setLoading(false);
      } else {
        setLoading(false);
      }
    }).catch(error => {
      console.log("faield:", error)
      navigate('/error');
    });
  }

  useEffect(() => {

    setLoading(true);

    if(!isConnected) 
    {
      setLoading(false);
      return;
    }

    start();

  }, [isConnected])

  let mainComponent = useMemo(() => {
      return isConnected ? <MainPage /> : <ConnectPage />;
  }, [isConnected])

  let tagsComponent = useMemo(() => {
    return isConnected ? <TagsPage /> : <ConnectPage />;
  }, [isConnected])

  let classifyComponent = useMemo(() => {
    return isConnected ? <ClassifyPage /> : <ConnectPage />;
  }, [isConnected])
  
  if(loading) <div className='flex items-center w-screen justify-center'><PageLoader label='Networking ;)'/></div>

  return (
    <>
      <Routes>
        <Route path='/get-started' element={<OnboardingPage />} />
        <Route path='/contact/:id' element={mainComponent} /> {/* <ContactPage /> */}
        <Route path='/tags' element={tagsComponent} />
        <Route path='/error' element={<ErrorPage />} />
        <Route exact path='/' element={mainComponent} />
        <Route path='/classify' element={classifyComponent} />
        <Route path='/browse' element={mainComponent} />
        <Route path='/list' element={mainComponent} />
        <Route path='/tag/:tag' element={mainComponent} />
        <Route path='/domain/:domain' element={mainComponent} />
      </Routes>
    </>
  );
}

export default App;
