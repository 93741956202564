import * as amplitude from '@amplitude/analytics-browser';

export class Analytics 
{
    static init()
    {
        amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY);
    }

    static setUserId(user)
    {
        amplitude.setUserId(user);
    }

    static trackEvent(eventName)
    {
        amplitude.track(eventName);
    }
}