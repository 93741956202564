import _ from 'lodash';
import classes from '../scss/contact.module.scss';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { selectContact, highlightContact, setShowNotesEditor, setNotification, hideNotification } from '../store/slices/ui.slice';
import { API, ContactHelper, NotificationHelper } from '../utils/api';
import { useNavigate } from "react-router-dom";
import { Fragment, useState } from 'react';
import { useEffect } from 'react';
import { Icon } from './icon.component';
import { Tag, TagsList } from './tag.component';
import { Tooltip } from './tooltip.component';
import { GhostButton } from './button.component';
import copy from 'clipboard-copy';
import { Shortcut } from './shortcut.component';
import { Markdown } from './markdown/markdown.component';
import { formatDistance } from 'date-fns';
import { useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { useRef } from 'react';

// const copy = require('clipboard-copy')

const EmptyPreview = () => {
    return <div className={classes.emptyPreview}>
        <Icon name={'folder'} className={classes.emptyPreviewIcon}/>
        <h5>No Contact Selected</h5>
        <p>Select Contact to Get Started</p>
    </div>
}



const ContactNumbersHeader = () => {
    return <div>
    <Icon name='phone' className={classes.dataIcon} />
    <h5>Numbers</h5>
    </div>;
}

let timeout = null;

export const ContactEmails = ({contact}) => {

    const { user: { token } } = useSelector(state => state);
    const dispatch = useDispatch();

    const copyEmail = (email) => {
        copy(email);
        dispatch(setNotification(NotificationHelper.buildNotification("Copied Successfully", `'${email}' copied to clipboard.`, NotificationHelper.SUCCESSS)));

        if(clearTimeout(timeout));
        timeout = setTimeout(() => dispatch(hideNotification()), 5000);
    };

    const onDelete = email => {
        API.deleteContactEmail(token, contact.id, email).then(contact => {
            dispatch(selectContact(contact));
        }).catch(error => {
            console.error('ContactEmails: failed to delete email. Error', error);
        })
    }

    const onPrimary = email => {
        API.makeContactEmailPrimary(token, contact.id, email).then(contact => {
            dispatch(selectContact(contact));
        }).catch(error => {
            console.error('ContactEmails: failed to make email primaru. Error', error);
        })
    }

    return <div className="isolate -space-y-px rounded-md shadow-sm">
        {_.map(_.orderBy(contact.emails, ['primary'], 'desc'), (email, index) => {
            return <div key={`${index}-${email}`} className="group relative rounded-md first:rounded-b-none last:rounded-t-none px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300">
                <label htmlFor={`email-${index}`} className="block text-xs text-gray-900">{email.primary ? "Primary Address" : `Address #${index}`}</label>
                <input type="text" name={`email-${index}`} readOnly id={`email-${index}`} className="block w-4/5 border-0 font-medium p-0 text-gray-900 placeholder:text-gray-400 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6" placeholder="Jane Smith" value={email.value} />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 z-11">
                <span className="isolate inline-flex rounded-md shadow-sm transition-opacity opacity-0 group-hover:opacity-100">
                    <button onClick={() => copyEmail(email.value)} type="button" className="rounded-l bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Copy</button>
                    <button onClick={() => onDelete(email.value)} type="button" className={`bg-white ${(email.primary) ? 'rounded-r' : ''} -ml-px px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50`}>Delete</button>
                    {!email.primary && <button onClick={() => onPrimary(email.value)} type="button" className="rounded-r -ml-px bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Set Primary</button>}
                </span>
            </div>
            </div>
        })}
    </div>
}

export const ContactPreviewEmails = ({contact, onEmptyCtaClick}) => {
    return <div className={classes.dataBox}>
        {/* <ContactEmailsHeader /> */}
        <section>
            {_.map(contact.emails, email => {
                return <div className={classes.dataItem}>
                    <div>
                        <span><i>{email.value}</i></span>
                        {email.primary && <div className={classes.primary}>
                            <small>Primary Address</small>
                            <Icon name="check" />
                        </div>}    
                    </div>
                    <aside><GhostButton icon="copy" label="copy" onClick={() => copy(email.value)}/></aside>
                </div>
            })}
            {/* {addEnabled && <EmptyState title={"New Email"} message={`Add an email address to ${ContactHelper.getPrimaryName(contact)}`} cta="Add Email" onClick={onEmptyCtaClick}/>} */}
        </section>
    </div>;
}

export const ContactNumbers = ({contact}) => {

    const { user: { token } } = useSelector(state => state);
    const dispatch = useDispatch();
    const [cpLabel, setCpLabel] = useState('copy');

    const copyNumber = (number) => {
        copy(number);
        setCpLabel('Copied');
        setTimeout(() => setCpLabel('Copy'), 1200);
    }

    const onDelete = phone => {
        API.deleteContactPhone(token, contact.id, phone).then(contact => {
            dispatch(selectContact(contact));
        }).catch(error => {
            console.error('ContactPhones: failed to delete phone. Error', error);
        })
    }

    const onPrimary = phone => {
        API.makeContactPhonePrimary(token, contact.id, phone).then(contact => {
            dispatch(selectContact(contact));
        }).catch(error => {
            console.error('ContactPhones: failed to make phone primaru. Error', error);
        })
    }

    return <div className="isolate -space-y-px rounded-md shadow-sm">
        {_.map(_.orderBy(contact.numbers, ['primary'], 'desc'), (number, index) => {
            return <div key={number.number.input} className="group relative rounded-md first:rounded-b-none last:rounded-t-none px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300">
                <label htmlFor={`number-${index}`} className="block text-xs text-gray-900">{number.primary ? "Primary Number" : `Number #${index}`}</label>

                <div className="mt-2 flex rounded-md">
                    <span className="inline-flex items-center text-gray-500 sm:text-sm w-6 mr-2">
                        <figure className="w-full">
                            <img src={`${process.env.PUBLIC_URL}/images/flags/${number.regionCode.toLowerCase()}.svg`} />
                        </figure>
                    </span>
                    <input type="text" name={`number-${index}`} readOnly id={`number-${index}`} className="block w-4/5 border-0 font-medium p-0 text-gray-900 placeholder:text-gray-400 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6" placeholder="Jane Smith" value={number.number.international} />
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 z-11">
                    <span className="isolate inline-flex rounded-md shadow-sm transition-opacity opacity-0 group-hover:opacity-100">
                        <button onClick={() => copyNumber(number.number.input)} type="button" className="rounded-l bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Copy</button>
                        <button onClick={() => onDelete(number.number.e164)} type="button" className={`bg-white ${(number.primary) ? 'rounded-r' : ''} -ml-px px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50`}>Delete</button>
                        {!number.primary && <button onClick={() => onPrimary(number.number.e164)} type="button" className="rounded-r -ml-px bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Set Primary</button>}
                    </span>
                </div>
            </div>
        })}
    </div>
};

export const ContactPreviewPhones = ({contact}) => {

    const [cpLabel, setCpLabel] = useState('copy');

    const copyNumber = (number) => {
        copy(number);
        setCpLabel('Copied');
        setTimeout(() => setCpLabel('Copy'), 1200);
    }

    return <div className={classes.dataBox}>
        <ContactNumbersHeader />
        <section>
            {_.map(contact.numbers, numberInfo => {
                let { number, primary, regionCode } = numberInfo;

                return <div className={classes.dataItem}>
                    <div>
                        <span>
                            <figure><img src={`${process.env.PUBLIC_URL}/images/flags/${regionCode.toLowerCase()}.svg`} /></figure>
                            <i>{number.international}</i>
                        </span>
                        {primary && <div className={classes.primary}>
                            <small>Primary Number</small>
                            <Icon name="check" />
                        </div>}    
                    </div>
                    <aside>
                        <GhostButton icon="copy" label={cpLabel} onClick={() => copyNumber(number.input)}/>
                    </aside>
                </div>
            })}
        </section>
    </div>;
}

export const ContactLastNote = ({contact, onCreateNoteClick}) => {
    
    const { user: { token, profile: { picture, name } } } = useSelector(state => state);
    const [note, setNote] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getNotes();
    }, [contact.id])

    const getNotes = async () => {
        return API.getNotes(token, contact.id).then(notes => {

            if(!_.isEmpty(notes)) setNote(_.first(notes));
            setLoading(false);

        }).catch(error => {
            setLoading(false);
        })
    }

    return <>
    <div className='w-full'>
        <ul>
            {!loading && _.isEmpty(note) && <li className='flex items-center space-x-2'>
                <span className='inline-block text-xs text-gray-900'>No notes</span>    
                <button onClick={onCreateNoteClick} type="button" className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Create</button>
            </li>}
            {!loading && !_.isEmpty(note) && <Note note={note} name={name} picture={picture} noTail={true} />}
        </ul>
    </div>
    </>
}

export const ContactDomains = ({contact}) => {

    const { user: { token } } = useSelector(state => state);
    const [stats, setStats] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if(_.isEmpty(contact.domains)) return;

        API.getDomainsStats(token, contact.domains).then(setStats).catch(error => {
            console.error('Failed to load domains stats. Error', error);
        })
    }, [contact.domains])

    const onDomainContactsClick = domain => {
        navigate(`/domain/${domain}`);
    }

    return <div className="isolate -space-y-px rounded-md shadow-sm">
        {_.map(contact.domains, (domain, index) => { 

            let domainStat = (stats[domain] && _.isNumber(stats[domain].total)) ? (stats[domain].total === 0 ? 0 : (stats[domain].total-1)) : 0;

            return <div key={domain} className="group relative rounded-md first:rounded-b-none last:rounded-t-none px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300">
                <label htmlFor={`domain-${index}`} className="block text-xs text-gray-900">{`${domainStat === 0 ? 'No other contacts for this domain' : `${domainStat} other contact${(stats[domain].total-1) > 1 ? 's are' : ' is'} related to the domain`}`}</label>
                <div className="mt-2 flex rounded-md">
                    <span className="inline-flex items-center text-gray-500 sm:text-sm w-4 mr-2">
                        <figure className="w-full">
                            <img src={`https://www.google.com/s2/favicons?domain=${domain}&sz=256`} />
                        </figure>
                    </span>
                    <input type="text" name={`domain-${index}`} readOnly id={`domain-${index}`} className="block w-4/5 border-0 font-medium p-0 text-gray-900 placeholder:text-gray-400 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6" value={ContactHelper.formatDomain(domain)} />
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 z-11 transition-opacity opacity-0 group-hover:opacity-100">
                    <button onClick={() => onDomainContactsClick(domain)} type="button" className="rounded-l bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">See Domain Contacts</button>
                    <button onClick={() => window.open(`https://${ContactHelper.formatDomain(domain)}`)} type="button" className="rounded-r -ml-px bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Go To</button>
                </div>
            </div>
        })}
    </div>
}

export const ContactNotes = ({contact}) => {

    const { user: { token, profile: { picture, name } } } = useSelector(state => state);
    const [notes, setNotes] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getNotes();
    }, [contact.id])

    const getNotes = async () => {
        return API.getNotes(token, contact.id).then(notes => {
            setNotes(notes);
            setLoading(false);
        }).catch(error => {
            setLoading(false);
        })
    }

    const saveNewNote = (note) => {
        setLoading(true);

        API.saveNote(token, contact.id, note).then(response => {
            if(response) getNotes();
        }).catch(error => {
            setLoading(false);
        });
    }

    return <Fragment>
        {!loading && <div className='w-full'>
            <ul role="list" className="space-y-6">
                <NotesEditor onSave={saveNewNote} picture={picture} name={name} />
                {!_.isEmpty(notes) && <> 
                    {_.map(notes, note => <Note note={note} name={name} picture={picture} />)}
                </>}
                <li className="relative flex gap-x-4">
                    <div className="absolute left-0 top-0 flex w-6 justify-center h-4">
                        <div className="w-px bg-gray-200"></div>
                    </div>
                    <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                    <CheckCircleIcon className="text-slate-800" />
                    </div>
                    <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">Contact <span className="font-medium text-gray-900">Created</span></p>
                    <time dateTime={contact.created} className="flex-none py-0.5 text-xs leading-5 text-gray-500">{`${formatDistance(new Date(contact.created), new Date())} ago`}</time>
                </li>
            </ul>
        </div>}
    </Fragment>
}

const Note = ({ note, name, picture, noTail }) => {

    let created = useMemo(() => {
        return note && note.created ? `${formatDistance(new Date(note.created), new Date())} ago` : null;
    }, [note.created])

    return <li className="relative flex gap-x-4">
        {!noTail && <div className="absolute left-0 top-0 flex w-6 justify-center -bottom-6">
            <div className="w-px bg-gray-200" />
        </div>}
        {!noTail && <img src={picture} alt="" className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50" />}
        <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
            <div className="flex justify-between gap-x-4">
                <div className="py-0.5 text-xs leading-5 text-gray-500"><span className="font-medium text-gray-900">{name}</span> commented</div>
                <time dateTime={new Date(note.created)} className="flex-none py-0.5 text-xs leading-5 text-gray-500">{created}</time>
            </div>
            <p className="text-sm leading-6 text-gray-500">{note.note}</p>
        </div>
    </li>;
}

export const NotesEditor = ({ onClose, onSave, picture, name, className }) => {

    const notesInput = useRef(null);
    const onSubmit = event => {
        event.preventDefault();
        onSave(notesInput.current.value);
    }

    return <li className={classNames(["mt-6", className])}>
        <div className="flex gap-x-3">
            {picture && <img src={picture} alt="" className="h-6 w-6 flex-none rounded-full bg-gray-50" />}
            <form action="#" className="relative flex-auto" onSubmit={onSubmit}>
                <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300">
                    <label htmlFor="comment" className="sr-only">Add your comment</label>
                    <Markdown onSave={onSave} onClose={onClose} placeholder={`Last update regarding ${name}...`} ref={notesInput} />
                </div>
                    <div className="absolute inset-x-0 bottom-0 flex justify-end py-2 pl-3 pr-3">
                    <button type="submit" className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        Comment <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">⌘⏎</span>
                    </button>    
                </div>
          </form>
        </div>
    </li>;
}

export const ContactPreview = ({contact}) => {

    const navigate = useNavigate();

    return <div className={classes.contactPreview}>
        {contact && <span>
            <header>
                <div data-moderated={contact.moderated ? "Verified" : ""}>
                    <h2>{ContactHelper.getPrimaryName(contact)}</h2>
                    <p>{ContactHelper.getTitle(contact)}</p>
                </div>
                <div>
                    <TagsList>
                        {contact && contact.tags && contact.tags.map(tag => <Tag>{tag}</Tag>)}
                    </TagsList>
                </div>
            </header>
            <main>
                {ContactHelper.isEmailAddressesAvaialble(contact) && <ContactPreviewEmails contact={contact} />}
                {ContactHelper.isPhoneNumbersAvailable(contact) && <ContactPreviewPhones contact={contact} />}
            </main>

            <div className={classes.previewActions}>
                <button onClick={() => navigate(`/contact/${contact.id}`)}>
                    <span>More Details <Shortcut shortcuts={["C"]} /></span>
                </button>
                {/* <button>
                    <span>Modify</span>
                </button>
                <button>
                    <span>Share</span>
                </button>
                <button>
                    <span>Export</span>
                </button> */}
            </div>
        </span>
        }
        {!contact && <EmptyPreview />}
    </div>
}

export const ContactsList = ({children, title, small}) => {

    return [title ? <h4 className={classes.contactsListTitle} key={`ct.${title}`}>{title}{small ? <small>{small}</small> : null}</h4> : null,<ul key={`cc.${children.length}`} className={classes.contactsList}>
        {children}
    </ul>]
}

const ContactActionButton = ({ icon, onClick }) => {

    return <button className={classes.actionButton} onClick={onClick}>
        <img src={icon} />
    </button>
}

const ContactImage = ({url}) => {

    const [image, setImage] = useState('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');

    useEffect(() => {
        if(!url) return;

        let imageElm = new Image();
        imageElm.onload = () => {
            setImage(url);
        }

        imageElm.onerror = () => {}
        imageElm.src = url;

    }, [url])

    return <img src={image} loading="lazy" />;   
}

export const ContactRow = ({ contact, onClick, isSelected }) => {

    // console.log(contact);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onContactClick = () => {
        dispatch(selectContact(contact));
        if(onClick) return onClick(contact);
        navigate(`/contact/${contact.id}`);
    }

    const onMouseEnter = () => {
        // dispatch(highlightContact(contact));
    }

    return <li key={`c${contact.id}`} className={classNames([classes.contactRow, {
        [classes.selected]: isSelected
    }])} onClick={onContactClick} onMouseEnter={onMouseEnter}>
        <div>
            <section>
                <figure>
                    <ContactImage url={contact.photo} />
                </figure>
                <div data-moderated={contact.moderated ? "Verified" : ""}>
                    <h5>{ContactHelper.getPrimaryName(contact)}</h5>
                    <span>{ContactHelper.getTitle(contact) !== 'N/A' ? `${ContactHelper.getTitle(contact)}  ■ ` : ''}{ContactHelper.getPrimaryEmailAddress(contact)}</span>
                </div>
            </section>
            
            {/* <div className={classes.actions}>

                <Tooltip title="Mark verified" shortcuts={['M']}>
                    <ContactActionButton icon="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZEAYAAACUeVkgAAABP2lDQ1BJQ0MgUHJvZmlsZQAAKJFjYGASSCwoyGFhYGDIzSspCnJ3UoiIjFJgf8bAysAOhOYMzInJxQWOAQE+QCUMMBoVfLvGwAiiL+uCzIo6NVPMQVMkxlCpyuRcsbIvpnoUwJWSWpwMpP8AcVJyQVEJAwNjApCtXF5SAGK3ANkiRUBHAdkzQOx0CHsNiJ0EYR8AqwkJcgayrwDZAskZiSlA9hMgWycJSTwdiQ21FwQ43I3MA50VHAk4lXRQklpRAqKd8wsqizLTM0oUHIEhlKrgmZesp6NgZGBkxMAACm+I6s83wOHIKMaBEOvexMBgv4iBgQlJnVMrA8OSuUAv7EKIacxnYJA+yMBwoLggsSgR7gDGbyzFacZGEDb3dgYG1mn//38OZ2Bg12Rg+Hv9///f2////7uMgYH5FlDvNwDN3VxvAv5FQgAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAGaADAAQAAAABAAAAGQAAAAB7O7VkAAACsklEQVRYCe2YP0xUQRDGD0IgIQTPxhCCSmFhBzGYEEjw5RJjTPyDDTUWdhRYmdiglb0JgZiQo9FCLTRqoYWnYmFngyYWeBg1KraY6DWg+eb3Lu8dl9vdw3cNFG92Z7/5ZnZ3dnaPXG7v798KHB7UOlyZlXxakvxSlvyzlZTowWEHj9AZfA8OyMmdouRPC3je+mcmpQfX2ak+Ej047FZKwjX6FiIh3jria/hwvGGB35gTZF++BvpfFOOjov1h/iciTzczljKfjeC4EXrSBMOPHJXpN/N/MvKkOmspwgQGLKU8aYLh+22nP9gELk17UpHDpFDWO9DWpoAflyRvFj0nAJxDzBlAn5W8ek2eXttEOjo8PVP+qEJZHWLCHEsdZjKDcWdJPaccOhs2CeztFcFHOwvn7GwG0z6zraTMBhN5Gi7bGbhl0tX82LCQd9N2X8saCN5S1wgMR1VcM789PX4EHAWqamxd2VKTGzgeSDW4UacCU4CyysKdiFIOHLtdXQL+trhjM95EjSYyZFtatpW8PicKymZMWKexVNRAs2eROIk7dscKuabWgT6ZUibvWYDd3TFlolGI1P1kC+CbSgmyv51Dg9LwCM3l2qVYXZccGpFs9N34LkThlOQvM3j1RI1+ewmQAgs20ZnLGt/cNINAQWa8W4fAJvL8oRSnzzPgJisV4aYvSt5/KflmRXJxUfL9C8lHDySb/RInccd8VIHduhAvWDEglUiF2GFgI5+XIXESdw1dq58oNQGlFDydbluqpoarXQ57qx6N1UiSLR6vxEWcSdQOPW54Lpysn/GEhF/iIC7GnWWrflixA0yAOJwDrwdkJdhacnW3XsnwwIsf/NaLK1hPblIUqB7c1DgGxw2MRA8OO3g4xOCCA/U1pPzxM4B/83Dj8oRAogeHHTy+/qv4bTYCuWc/TbVeAAAAAElFTkSuQmCC" />
                </Tooltip>

                
                <Tooltip title="Archive Contact" shortcuts={['H']}>
                    <ContactActionButton icon="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYEAYAAACw5+G7AAABP2lDQ1BJQ0MgUHJvZmlsZQAAKJFjYGASSCwoyGFhYGDIzSspCnJ3UoiIjFJgf8bAysAOhOYMzInJxQWOAQE+QCUMMBoVfLvGwAiiL+uCzIo6NVPMQVMkxlCpyuRcsbIvpnoUwJWSWpwMpP8AcVJyQVEJAwNjApCtXF5SAGK3ANkiRUBHAdkzQOx0CHsNiJ0EYR8AqwkJcgayrwDZAskZiSlA9hMgWycJSTwdiQ21FwQ43I3MA50VHAk4lXRQklpRAqKd8wsqizLTM0oUHIEhlKrgmZesp6NgZGBkxMAACm+I6s83wOHIKMaBEOvexMBgv4iBgQlJnVMrA8OSuUAv7EKIacxnYJA+yMBwoLggsSgR7gDGbyzFacZGEDb3dgYG1mn//38OZ2Bg12Rg+Hv9///f2////7uMgYH5FlDvNwDN3VxvAv5FQgAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAGKADAAQAAAABAAAAGAAAAADB/VeXAAACZUlEQVRYCb1XPU8UURRdRPnKjpJIYQIJUxHQwh9gA4nBRhNbK2NiTWcoTfgPdiSU0NFQERK2M7GQxljKizQQiFmdxQUCaPTcs8Pc3ct7szPsFnM/z7nvvo/ZN32VUn79/aAZHMxHd3qK/IuLfLg0uy9Vu9HmZ4FaXYG8zElyS/JfvYGyWctJUDR9YxsML152x0QcefKz3O4MiWP4h4c6x+l9InlL+/DMTDMSJg8ERx4fvnkCXufIr7YQ9/L2FhJGY8gpkV9qsHv1fDiLSt8c5A+Rc09he8/O/TEkft+F7PXzq9SdjK3KPEVGPPmFQGTEb9rNukmjYKXjPwUJuoTXpe7AgEXgWQHCmg7a8Ag9YXJ8AnmUYag0646oZ2epL6sFNsAVrBoNjMlZeaTeQq/fohwlizOPOPopOVGcOPrbZWADiSCjajvFP8+z5/AvLHaOay/ziNPx6C48rKvjqR3agAMkGk2hVzX1Nr4aula3cNEQYOU1IMOwGrh2lF0EqzJRDecDB64AiUjsoy0a50SVvwJFRxaIbzXgfIDAFeBMkNhHWzTe+gPzEoU24MDUqwa4Vfn6tvsIbKAhrwsSa0J+j1DquLaZR6njnKjE6Yi2jeu0Tkvq8Izf0xHY62uQWxvZ+Lngst5K5f07eKw7TqsBDWyzczYQPW5j+O84kXs6JbM+LFPLysOjrK2tSO5Aez91RNuBW4grwMOlaSz7WDYxpZWn/dUYnoaxgml+YAMkMm4SKV9JGieKE2fTBjZQly3yILapyoywTt27AoFnYOczhteUUf6WPVr2ZwLvRjs1FPr4SQqa4i//PIFzW2n2vwAAAABJRU5ErkJggg==" />
                </Tooltip>
                
                <Tooltip title="Other Commands" shortcuts={['⌘', 'K']}>
                    <ContactActionButton icon="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYEAYAAACw5+G7AAABP2lDQ1BJQ0MgUHJvZmlsZQAAKJFjYGASSCwoyGFhYGDIzSspCnJ3UoiIjFJgf8bAysAOhOYMzInJxQWOAQE+QCUMMBoVfLvGwAiiL+uCzIo6NVPMQVMkxlCpyuRcsbIvpnoUwJWSWpwMpP8AcVJyQVEJAwNjApCtXF5SAGK3ANkiRUBHAdkzQOx0CHsNiJ0EYR8AqwkJcgayrwDZAskZiSlA9hMgWycJSTwdiQ21FwQ43I3MA50VHAk4lXRQklpRAqKd8wsqizLTM0oUHIEhlKrgmZesp6NgZGBkxMAACm+I6s83wOHIKMaBEOvexMBgv4iBgQlJnVMrA8OSuUAv7EKIacxnYJA+yMBwoLggsSgR7gDGbyzFacZGEDb3dgYG1mn//38OZ2Bg12Rg+Hv9///f2////7uMgYH5FlDvNwDN3VxvAv5FQgAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAGKADAAQAAAABAAAAGAAAAADB/VeXAAADGklEQVRYCcVYS2sTURidvlupbURFwTYMqPhYKSg+EAmCC11IRXygYqa4ESylC5dii+BPUCsWhqLoQoS4EEHUpIilC19YpSqFRFvE2pUV1F2snHMGnOQ2uWGIXcy5937fd75zH/PdSR0n0r+6OtDd9IE/8sA77Dc0RJouerIDXeDMU3gYj3nR54yEsa0NNI/SwLBw9cezsK9bH0naeRJueaV0rovIx2PEFPDBCHApwBkcRiN1D3jrOnDmO/DNa2DVnju2I9UUVzTp2aVewx14yR0b8hHfssiOx9pbZ3iawncnrCn+CWhqQvcKJ6AjtiGyI8Z0FwbQmKBwrSDNkcFhFoEvzHPKs6TeyaOxlahy+CQNoiUxS8IK3Ve7CHzBvL6P/jbqks6A/jIdVC2EIyT4X/VbRyxDHdIllG5HF44Mwsks5hh3g7lWtaEJ6OhKl3AuXws9o5niumZzGH/KFXhFvNiP8S3c0pqa4vGtrRg/6QFVBJqbi/urul31Yf/8Hmigd0YzsDuLeRFdY6DOfH09HQibN6Ghl/p5Gn1VpUHG60Z+l4VdKyYcY9z5Adg/0k98vX0YX74MKB1pxkmndMNr/rliJZpfSajAwMHQWNUBwxkmlhAJNuFtH3EbS5RN6ZAu6TTIcZyHnOn+LqPLgobjHswm4Rrfm1iQJjBKh3QFBlPD82C5wRUy+ZnGGxthecaFkGDhfY6b3p0wr8q5dIXtBf32GIZms8BKr3htvVZQK16ucOWVDn00Fgg2DdzlDhyp8CiZeMsdP8q80lFuXOB3iAQpbnlgqFJDeaXDOq3qtbYwxqNlTWQZoDzfeISlo5DGdEOEPId5lOTe4cJhIgc8dxb46yfQ9nnaQ8SJJJAfqc5kDv1kN7Di5z4eJVWRMA5xgnsSSKEbei3ru+q2XkoJOViC9y13wPzSa0nFaMC4C8MnEobdxnO0E3mxO+0uxtUXtnBcFz2rLpyLPOOdGJyaLmK0GbrUD+/feaB+gHTyJrbh+uvb04eI8I6qP8MF00edLb/Rv5YfgUaHMg36N4x+kc1xYT5Q+K5EKaI/b+jwBHpHQNYAAAAASUVORK5CYII=" />
                </Tooltip>
            </div> */}
        </div>
    </li>
}

